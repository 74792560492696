import { RootState } from '@store/types';
import styles from "./Header.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Dropdown } from '@shared/components';
import { useEffect, useState } from "react";
import i18next from "i18next";
import defaultUserImg from "@assets/images/defaultUser.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useModals } from '@shared/contexts/modal-context';
import { ModalName } from '@shared/components/modals/types';
import { RuIcon, KzIcon, ParimatchIcon, ProfileIcon, PmPointIcon, CoinIcon, RocketsLogo, EnIcon, UkIcon } from '@shared/icons';
import { useIsInIframe } from '@/shared/hooks';
import { MobMenu } from './MobMenu/MobMenu';
import { Routes } from '@/routes/constants';

const isRockets = process.env.REACT_APP_BRAND === 'rockets';

const HeaderIcon = () => {
  return (
    <NavLink to="https://parimatch.kz/" target="_blank" className={styles.logo}>
      
      {
        isRockets ? <RocketsLogo /> : <ParimatchIcon />
      }
    </NavLink>
  )
}

const RocketsHeader = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');
  const { t } = useTranslation("main");
  const { openModal } = useModals();

  const isInIframe = useIsInIframe();

  const handleLanguageChange = (value: string | number) => {
    const lng = value.toString();
    i18next.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setLanguage(lng);
  };

  useEffect(() => {
    if (isInIframe) {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang')

      if (lang) {
        setLanguage(lang);
        i18next.changeLanguage(lang);
        localStorage.setItem('language', lang);
      }
    }
  }, [isInIframe])

  const [active, setActive] = useState<string | null>(null);

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);

  const handleLoginClick = () => {
    openModal(ModalName.LOGIN);
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === Routes.HOME) {
      setActive('Главная');
    } else if (path === Routes.SHOP) {
      setActive('Магазин');
    } else if (path === Routes.QUESTS) {
      setActive('Квесты');
    } else if (path === Routes.TOURNAMENTS) {
      setActive('Турниры');
    } else if (path === Routes.HELP) {
      setActive('Поддержка');
    } else if (path === Routes.FAQ) {
      setActive('Частые вопросы');
    } else if (path === Routes.PROFILE) {
      setActive('Профиль');
    }
  }, [location]);

  const showHeader = location.pathname !== Routes.WELCOME;


  return (
    <>
      {
        !isInIframe && (
          <HeaderIcon />
        )
      }
      {
        showHeader &&
        <div className={styles.container}>
          <div className={styles.content} style={{ paddingTop: isInIframe ? '70px' : '0' }}>
            <div className={styles.left}>
              <div className={styles.nav}>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Главная' ? styles.active : ''}`} to="/">
                    { isRockets ? t("bonus_shop") : t("shop")}
                  </NavLink>
                </div>
                <div>
                    <NavLink className={`${styles.hoverY} ${active === 'Квесты' ? styles.active : ''}`} to="/quests">
                      {t("quests")}
                    </NavLink>
                  </div>
               
                {isAuthenticated &&
                  <div>
                    <NavLink className={`${styles.hoverY} ${active === 'Профиль' ? styles.active : ''}`} to="/profile">
                      {t("buy_history")}
                    </NavLink>
                  </div>
                }
              </div>
            </div>

            <div className={styles.right}>
              {isAuthenticated ? (
                <div className={styles.auth}>
                  <div className={ isRockets ? styles.points_row : ''}>
                    <div className={styles.points}>
                      <CoinIcon /> Баллы
                    </div>
                    <div className={styles.pointsValue}>
                      {user?.pm_points} 
                    </div>
                  </div>
                </div>
              ) :
                <>
                  <Button className={styles.loginBtn} title={t("enter")} onClick={handleLoginClick} size="large" />
                  <div className={styles.login} onClick={handleLoginClick}>
                    <ProfileIcon />
                    Войти
                  </div>
                </>
              }

              {
                !isInIframe &&
                <Dropdown
                  options={ isRockets ?
                    [
                      { value: 'ru', label: 'RU', icon: <RuIcon /> },
                      { value: 'uk', label: 'UK', icon: <UkIcon /> },
                      { value: 'en', label: 'EN', icon: <EnIcon /> }
                    ]
                     :
                     [
                    { value: 'ru', label: 'RU', icon: <RuIcon /> },
                    { value: 'kz', label: 'KZ', icon: <KzIcon /> }
                  ]}
                  value={language}
                  onChange={handleLanguageChange}
                  className={styles.langDropdown}
                />
              }
            </div>
          </div>

          <MobMenu />
        </div>
      }
    </>
  )
}

export const Header = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');
  const { t } = useTranslation("main");
  const { openModal } = useModals();

  const isInIframe = useIsInIframe();

  const handleLanguageChange = (value: string | number) => {
    const lng = value.toString();
    i18next.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setLanguage(lng);
  };

  const [active, setActive] = useState<string | null>(null);

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);

  const handleLoginClick = () => {
    openModal(ModalName.LOGIN);
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === Routes.HOME) {
      setActive('Главная');
    } else if (path === Routes.SHOP) {
      setActive('Магазин');
    } else if (path === Routes.QUESTS) {
      setActive('Квесты');
    } else if (path === Routes.TOURNAMENTS) {
      setActive('Турниры');
    } else if (path === Routes.HELP) {
      setActive('Поддержка');
    } else if (path === Routes.FAQ) {
      setActive('Частые вопросы');
    } else if (path === Routes.PROFILE) {
      setActive('Профиль');
    }
  }, [location]);

  const showHeader = location.pathname !== Routes.WELCOME;

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  if (isRockets) {
    return <RocketsHeader />
  }

  return (
    <>
      {
        !isInIframe && (
          <HeaderIcon />
        )
      }
      {
        showHeader &&
        <div className={styles.container}>
          <div className={styles.content} style={{ paddingTop: isInIframe ? '70px' : '0' }}>
            <div className={styles.left}>
              <NavLink to="/" className={styles.pmPass}>
                PM Pass
              </NavLink>
              <div className={styles.nav}>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Главная' ? styles.active : ''}`} to="/">
                    {t("main")}
                  </NavLink>
                </div>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Магазин' ? styles.active : ''}`} to="/shop">
                    {t("shop")}
                  </NavLink>
                </div>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Квесты' ? styles.active : ''}`} to="/quests">
                    {t("quests")}
                  </NavLink>
                </div>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Турниры' ? styles.active : ''}`} to="/tournaments">
                    {t("tournaments")}
                  </NavLink>
                </div>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Поддержка' ? styles.active : ''}`} to="/help">
                    {t("help")}
                  </NavLink>
                </div>
                <div>
                  <NavLink className={`${styles.hoverY} ${active === 'Частые вопросы' ? styles.active : ''}`} to="/faq">
                    FAQ
                  </NavLink>
                </div>
              </div>
            </div>

            <div className={styles.right}>
              {isAuthenticated ? (
                <div className={styles.auth}>
                  <div>
                    <div className={styles.points}>
                      <PmPointIcon /> Баллы
                    </div>
                    <div className={styles.pointsValue}>
                      {user?.pm_points}
                    </div>
                  </div>
                  <div className={styles.line}></div>
                  <div className={styles.profile}>
                    <ProfileIcon />
                    <div>
                      <NavLink className={`${styles.hoverY} ${active === 'Профиль' ? styles.active : ''}`} to="/profile">
                        Профиль
                      </NavLink>
                    </div>
                  </div>
                </div>
              ) :
                <>
                  <Button className={styles.loginBtn} title={t("enter")} onClick={handleLoginClick} size="large" />
                  <div className={styles.login} onClick={handleLoginClick}>
                    <ProfileIcon />
                    Войти
                  </div>
                </>
              }

              <Dropdown
                options={[
                  { value: 'ru', label: 'RU', icon: <RuIcon /> },
                  { value: 'kz', label: 'KZ', icon: <KzIcon /> }
                ]}
                value={language}
                onChange={handleLanguageChange}
                className={styles.langDropdown}
              />
            </div>
          </div>

          <MobMenu />
        </div>
      }
    </>
  );
};
