import { Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './TradeLinkHelpModal.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import telegram from '@assets/images/social/telegram.svg'
import phone from '@assets/images/social/phone.svg'

export const TradeLinkHelpModal = () => {
  const { isModalOpen, closeModal } = useModals();

  const { t } = useTranslation(["main", "help", "welcome"]);

  return (
    <Modal isOpen={isModalOpen(ModalName.TRADE_LINK_HELP)} onClose={closeModal}>
      <div className={styles.title}>
        {t("welcome:trade_link_text2")}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.subtitle}>{t("help:find_trade_link")}</div>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            {t("help:find_nickname")}
          </li>
          <li className={styles.listItem}>
            {t("help:go_to")}
          </li>
          <li className={styles.listItem}>
            {t("help:click_button")}
          </li>
          <li className={styles.listItem}>
            {t("help:open")}
          </li>
          <li className={styles.listItem}>
            {t("help:trade_text1")}
          </li>
        </ul>
        <div className={styles.listItem_down}>{t("help:down_here")}</div>
      </div>
    </Modal>
  )
}