import styles from "./RulesTab.module.scss";
import cup from "@assets/images/cybermarathon/cup.png";
import king from "@assets/images/cybermarathon/king.png";
import gun from "@assets/images/cybermarathon/gun.png";
import { useTranslation } from "react-i18next";

export const RulesTab = () => {
  const { t } = useTranslation('cybermarathon');
  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>
        {t('rules_item_title')}
      </div>

      <div className={styles.subtitle}>
        {t('rules_item_description')}
      </div>

      <div className={styles.rules}>
        <div className={styles.rulesItem}>
          <div className={styles.rulesItemImage}>
            <img src={cup} alt="rules" />
          </div>
          <div className={styles.rulesItemTitle}>
            {t('rules_item_title_2')}
          </div>
          <div className={styles.rulesItemDescription}>
            {t('rules_item_description_2')}
          </div>
          <div className={styles.rulesItemDescription}>
            {t('rules_item_description_3')}
          </div>
        </div>

        <div className={styles.rulesItem}>
          <div className={styles.rulesItemImage}>
            <img src={king} alt="rules" />
          </div>
          <div className={styles.rulesItemTitle}>
            {t('rules_item_title_3')}
          </div>
          <div className={styles.rulesItemDescription}>
            {t('rules_item_description_4')}
          </div>
        </div>

        <div className={styles.rulesItem}>
          <div className={styles.rulesItemImage}>
            <img src={gun} alt="rules" />
          </div>
          <div className={styles.rulesItemTitle}>
            {t('rules_item_title_4')}
          </div>
          <div className={styles.rulesItemDescription}>
            {t('rules_item_description_5')}
          </div>
        </div>
      </div>

    </div>
  );
};