import { useState } from "react";
import styles from "./TradeLink.module.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "@/shared/components";
import TradeLinkHelp from "../../../components/TradeLinkHelp/TradeLinkHelp";
import loader from "../../../assets/images/icons/loader.svg";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { updateAuth } from "@store/slices/auth/authSlice";
import { useOnboardingPassed } from "@/shared/hooks";
const TradeLink = ({ onClickPrevious, onClickNext }) => {
  const { t } = useTranslation(["main", "welcome", "notifications"]);

  const [link, setLink] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate()

  const handleChange = (e) => {
    const value = e.target.value;
    setLink(value);

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    setIsValid(urlRegex.test(value));
  };

  const handleTradeClick = () => {
    document.body.style.overflow = "hidden";
    setIsFormOpen(true);
  };

  const closeForm = () => {
    document.body.style.overflow = "auto";
    setIsFormOpen(false);
  };

  const attachTradeLink = async (link) => {
    if (!link) {
      setIsValid(false);
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('auth_token')
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile`,
        {
          onboarding_passed: true,
          steam_trade_url: link,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );

      if (response.data) {
        dispatch(
          updateAuth({
            isAuthenticated: true,
            user: response.data,
          })
        );

        toast(<Toast message={t("notifications:trade_link_attached")} />, {
          hideProgressBar: true,
        });

        navigate("/");
      }
    } catch (error) {
      setIsValid(false);
      toast(
        <Toast message={t("notifications:wrong_trade_link")} status="error" />,
        {
          hideProgressBar: true,
        }
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const Enter = () => {
    if (!isValid) {
      toast(
        <Toast message={t("notifications:wrong_trade_link")} status="error" />,
        {
          hideProgressBar: true,
        }
      );

      return;
    }

    attachTradeLink(link);
  };

  const { markOnboardingPassed } = useOnboardingPassed();
  const handleMarkOnboardingPassed = () => {
    markOnboardingPassed();
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.trade}>
          <div className={styles.trade_grid}>
            <div onClick={onClickPrevious} className={styles.back}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.3 13.2C7.9 12.8 7.9 12.2 8.3 11.8C9.4 10.7 11.7 8.4 12.8 7.3C13.2 6.9 13.8 6.9 14.2 7.3C14.6 7.7 14.6 8.3 14.2 8.7C13.3 9.6 10.4 12.5 10.4 12.5C10.4 12.5 13.3 15.3 14.2 16.3C14.6 16.7 14.6 17.3 14.2 17.7C13.8 18.1 13.2 18.1 12.8 17.7C11.7 16.6 9.4 14.3 8.3 13.2Z"
                  fill="#BBB5AA"
                />
              </svg>
            </div>
            <div className={styles.title}>Trade Link</div>
          </div>

          <div className={styles.info}>{t("welcome:trade_link_text1")}</div>
          <div className={styles.inputWrapper}>
            <div>{t("link")}</div>
            <div className={`${styles.input} ${isValid ? "" : styles.error}`}>
              <input
                onChange={handleChange}
                type="text"
                value={link}
                placeholder="https://"
              />
            </div>
          </div>

          <div className={styles.btns}>
            <div className={styles.getTrade} onClick={handleTradeClick}>
              {t("welcome:trade_link_text2")}
            </div>
            <div className={styles.btns_group}>
              <NavLink to="/" className={styles.skipBtn} onClick={handleMarkOnboardingPassed}>
                <button>{t("skip")}</button>
              </NavLink>
              <div className={styles.btn} onClick={Enter}>
                <button disabled={loading}>
                  {loading ? (
                    <img className={styles.loader} src={loader} alt="" />
                  ) : (
                    t("attach")
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className={styles.skipInfo}>{t("welcome:skip_step")}</div>
        </div>
      </div>
      {isFormOpen && <TradeLinkHelp closeForm={closeForm} />}
    </div>
  );
};

export default TradeLink;
