import { Button } from "@/shared/components";
import styles from "./CybermarathonBanner.module.scss";
import cybermarathon from "@assets/images/cybermarathon.webp";
import { NavLink } from "react-router-dom";
import { Routes } from "@/routes/constants";

export const CybermarathonBanner = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <img src={cybermarathon} alt="cybermarathon" />
      </div>
      <div className={styles.right}>
        <div className={styles.title}>
          Врывайтесь в весенний Кибермарафон с Parimatch
        </div>
        <div className={styles.description}>
          Участвуйте в турнирах каждый месяц и выигрывайте крутые призы.
        </div>
        <div>
          <Button>
            <NavLink to={Routes.CYBERMARATHON}>
              Узнать больше
            </NavLink>
          </Button>
        </div>
      </div>
    </div>
  );
};