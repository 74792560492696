import styles from "./Cards.module.scss";
import quests from "../../assets/images/cards/quests.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/images/cards/arrow-left.svg";

const Cards = () => {
  const { t } = useTranslation("main");

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_inner_second_group}>
          <NavLink className={styles.wrapper_inner_item} to="/quests">
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
                {t("quests")}
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
          <NavLink className={styles.wrapper_inner_item} to='/shop'>
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
                {t("shop")}
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
          <NavLink className={styles.wrapper_inner_item} to="/tournaments">
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
              {t("tournaments")}
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
          <NavLink className={styles.wrapper_inner_item} to="/cybermarathon">
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
                Кибермарафон
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
          <NavLink className={styles.wrapper_inner_item} to='/profile'>
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
                Профиль
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
          <NavLink className={styles.wrapper_inner_item} to='https://parimatch.kz/'>
            <div className={styles.wrapper_inner_text_group}>
              <div className={styles.wrapper_inner_text_group_first}>
                Делай ставки <br /> с Parimatch
              </div>
              <div className={styles.wrapper_inner_text_group_second}>
                {t("go")}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16669 14.3333C7.83335 14 7.83335 13.5 8.16669 13.1666C8.91669 12.4166 11.3334 9.99996 11.3334 9.99996C11.3334 9.99996 8.91669 7.66663 8.16669 6.83329C7.83335 6.49996 7.83335 5.99996 8.16669 5.66663C8.50002 5.33329 9.00002 5.33329 9.33335 5.66663C10.25 6.58329 12.1667 8.49996 13.0834 9.41663C13.4167 9.74996 13.4167 10.25 13.0834 10.5833C12.4167 11.25 10.3334 13.4166 9.33335 14.3333C9.00002 14.6666 8.50002 14.6666 8.16669 14.3333Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Cards;
