import { LoginModal } from '../login-modal';
import { RegisterModal } from '../register-modal';
import { HelpModal } from '../help-modal';
import { OfferRulesModal } from '../offer-rules-modal';
import { OfferModal } from '../offer-modal';
import { BonusModal } from '../bonus-modal';
import { TradeLinkHelpModal } from '../trade-link-help-modal';
import { ConfirmModal } from '../confirm-modal';
import { MoreDetailsModal } from '../more-details-modal';
import { ItemDetailsModal } from '../item-details-modal';

export const ModalContainer = () => {
  return (
    <>
      <LoginModal />
      <RegisterModal />
      <HelpModal />
      <OfferRulesModal />
      <OfferModal />
      <BonusModal />
      <TradeLinkHelpModal />
      <ConfirmModal />
      <MoreDetailsModal />
      <ItemDetailsModal />
      {/* Add other modals here */}
    </>
  );
}; 