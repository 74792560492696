import React from "react";
import styles from "./Prefooter.module.scss";
import pic from "@/assets/images/prefooter_img.webp";
import { useTranslation } from "react-i18next";
import { ModalName } from "@/shared/components";
import { useModals } from "@/shared/contexts";

export const Prefooter = () => {
  const { t } = useTranslation("prefooter");

  const { openModal } = useModals();

  const handleRegisterClick = () => {
    openModal(ModalName.REGISTER);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.group}>
          <h1 className={styles.title}>
            {t("title")} <span>{t("title_hg")}</span>
          </h1>
          <div className={styles.desc_group}>
            <p className={styles.desc}>
              {t("desc1")} <span>{t("desc1_hg")}</span> {t("desc1_end")}
            </p>
            <p className={styles.desc}>
              {t("desc2")} <span>{t("desc2_hg")}</span> {t("na")}{" "}
              <span>{t("desc1_end_hg")}</span>
            </p>
          </div>
          <button className={styles.btn} onClick={handleRegisterClick}>
            Старт
          </button>
        </div>
        <img src={pic} className={styles.group_img} alt="Prefooter" />
      </div>
    </div>
  );
};
