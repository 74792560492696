export const CopyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7142_16239" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7142_16239)">
        <path d="M6 12.0007C5.63333 12.0007 5.31944 11.8701 5.05833 11.609C4.79722 11.3479 4.66667 11.034 4.66667 10.6673V2.66732C4.66667 2.30065 4.79722 1.98676 5.05833 1.72565C5.31944 1.46454 5.63333 1.33398 6 1.33398H12C12.3667 1.33398 12.6806 1.46454 12.9417 1.72565C13.2028 1.98676 13.3333 2.30065 13.3333 2.66732V10.6673C13.3333 11.034 13.2028 11.3479 12.9417 11.609C12.6806 11.8701 12.3667 12.0007 12 12.0007H6ZM3.33333 14.6673C2.96667 14.6673 2.65278 14.5368 2.39167 14.2757C2.13056 14.0145 2 13.7007 2 13.334V4.66732C2 4.47843 2.06389 4.3201 2.19167 4.19232C2.31944 4.06454 2.47778 4.00065 2.66667 4.00065C2.85556 4.00065 3.01389 4.06454 3.14167 4.19232C3.26944 4.3201 3.33333 4.47843 3.33333 4.66732V13.334H10C10.1889 13.334 10.3472 13.3979 10.475 13.5257C10.6028 13.6534 10.6667 13.8118 10.6667 14.0007C10.6667 14.1895 10.6028 14.3479 10.475 14.4756C10.3472 14.6034 10.1889 14.6673 10 14.6673H3.33333Z" fill="#BBB5AA" />
      </g>
    </svg>
  )
}
