export const GalaxyIcon = () => {
  return <svg width="131" height="40" viewBox="0 0 131 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.144531 4.36524C0.144531 1.95439 2.10625 0 4.52615 0H126.335C128.755 0 130.717 1.95439 130.717 4.36524V34.922C130.717 37.3328 128.755 39.2872 126.335 39.2872H4.52615C2.10625 39.2872 0.144531 37.3328 0.144531 34.922V4.36524Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M126.335 0.873049H4.52615C2.59023 0.873049 1.02085 2.43656 1.02085 4.36524V34.922C1.02085 36.8506 2.59023 38.4142 4.52615 38.4142H126.335C128.271 38.4142 129.84 36.8506 129.84 34.922V4.36524C129.84 2.43656 128.271 0.873049 126.335 0.873049ZM4.52615 0C2.10625 0 0.144531 1.95439 0.144531 4.36524V34.922C0.144531 37.3328 2.10625 39.2872 4.52615 39.2872H126.335C128.755 39.2872 130.717 37.3328 130.717 34.922V4.36524C130.717 1.95439 128.755 0 126.335 0H4.52615Z" fill="white" />
    <path d="M41.7432 14.946L43.6964 10.3984H44.4215L46.5031 14.946H45.7364L45.1431 13.5687H43.0164L42.4579 14.946H41.7432ZM43.2107 13.0786H44.935L44.4042 11.8192C44.2423 11.4366 44.122 11.1222 44.0433 10.8762C43.9786 11.1677 43.8872 11.4573 43.7693 11.7447L43.2107 13.0786Z" fill="white" />
    <path d="M47.8527 14.946L46.4511 11.6517H47.1103L47.9013 13.6245C47.9868 13.8376 48.0655 14.0588 48.1372 14.2884C48.1927 14.1147 48.2702 13.9058 48.3696 13.6618L49.1884 11.6517H49.8302L48.4356 14.946H47.8527Z" fill="white" />
    <path d="M52.7861 14.5396C52.5548 14.7154 52.3316 14.8395 52.1165 14.9119C51.9038 14.9843 51.6748 15.0205 51.4296 15.0205C51.0248 15.0205 50.7138 14.9326 50.4964 14.7568C50.2789 14.5789 50.1702 14.3525 50.1702 14.0774C50.1702 13.9161 50.2107 13.7693 50.2917 13.637C50.3749 13.5025 50.4825 13.395 50.6143 13.3143C50.7485 13.2337 50.8988 13.1727 51.0653 13.1313C51.1879 13.1024 51.3729 13.0745 51.6204 13.0476C52.1246 12.9938 52.4959 12.9297 52.7341 12.8552C52.7364 12.7787 52.7376 12.7301 52.7376 12.7094C52.7376 12.482 52.6786 12.3217 52.5606 12.2286C52.401 12.1025 52.164 12.0394 51.8494 12.0394C51.5557 12.0394 51.3382 12.0859 51.1972 12.179C51.0584 12.27 50.9555 12.4323 50.8884 12.666L50.2778 12.5916C50.3333 12.3579 50.4247 12.1697 50.5519 12.027C50.6791 11.8822 50.8629 11.7716 51.1035 11.6951C51.344 11.6165 51.6227 11.5772 51.9396 11.5772C52.2542 11.5772 52.5097 11.6103 52.7063 11.6765C52.9029 11.7427 53.0475 11.8264 53.14 11.9277C53.2325 12.027 53.2973 12.1532 53.3343 12.3062C53.3551 12.4013 53.3655 12.573 53.3655 12.8211V13.5656C53.3655 14.0847 53.3782 14.4135 53.4037 14.5521C53.4314 14.6885 53.4846 14.8199 53.5633 14.946H52.911C52.8463 14.8302 52.8046 14.6947 52.7861 14.5396ZM52.7341 13.2926C52.5074 13.3754 52.1674 13.4457 51.7141 13.5036C51.4574 13.5367 51.2758 13.5739 51.1694 13.6152C51.063 13.6566 50.9809 13.7176 50.9231 13.7983C50.8653 13.8768 50.8363 13.9647 50.8363 14.0619C50.8363 14.2108 50.8988 14.3349 51.0237 14.4342C51.1509 14.5334 51.3359 14.5831 51.5788 14.5831C51.8193 14.5831 52.0333 14.5365 52.2206 14.4435C52.408 14.3484 52.5456 14.2191 52.6335 14.0557C52.7005 13.9296 52.7341 13.7435 52.7341 13.4974V13.2926Z" fill="white" />
    <path d="M54.3404 11.0406V10.3984H54.9649V11.0406H54.3404ZM54.3404 14.946V11.6517H54.9649V14.946H54.3404Z" fill="white" />
    <path d="M55.9051 14.946V10.3984H56.5296V14.946H55.9051Z" fill="white" />
    <path d="M59.9052 14.5396C59.674 14.7154 59.4508 14.8395 59.2357 14.9119C59.0229 14.9843 58.7939 15.0205 58.5487 15.0205C58.144 15.0205 57.8329 14.9326 57.6155 14.7568C57.3981 14.5789 57.2894 14.3525 57.2894 14.0774C57.2894 13.9161 57.3298 13.7693 57.4108 13.637C57.494 13.5025 57.6016 13.395 57.7334 13.3143C57.8676 13.2337 58.0179 13.1727 58.1844 13.1313C58.307 13.1024 58.4921 13.0745 58.7395 13.0476C59.2438 12.9938 59.615 12.9297 59.8532 12.8552C59.8555 12.7787 59.8567 12.7301 59.8567 12.7094C59.8567 12.482 59.7977 12.3217 59.6797 12.2286C59.5201 12.1025 59.2831 12.0394 58.9685 12.0394C58.6748 12.0394 58.4574 12.0859 58.3163 12.179C58.1775 12.27 58.0746 12.4323 58.0075 12.666L57.3969 12.5916C57.4524 12.3579 57.5438 12.1697 57.671 12.027C57.7982 11.8822 57.9821 11.7716 58.2226 11.6951C58.4632 11.6165 58.7419 11.5772 59.0587 11.5772C59.3733 11.5772 59.6289 11.6103 59.8255 11.6765C60.022 11.7427 60.1666 11.8264 60.2591 11.9277C60.3516 12.027 60.4164 12.1532 60.4534 12.3062C60.4742 12.4013 60.4846 12.573 60.4846 12.8211V13.5656C60.4846 14.0847 60.4973 14.4135 60.5228 14.5521C60.5505 14.6885 60.6037 14.8199 60.6824 14.946H60.0301C59.9654 14.8302 59.9237 14.6947 59.9052 14.5396ZM59.8532 13.2926C59.6265 13.3754 59.2865 13.4457 58.8332 13.5036C58.5765 13.5367 58.3949 13.5739 58.2885 13.6152C58.1821 13.6566 58.1 13.7176 58.0422 13.7983C57.9844 13.8768 57.9555 13.9647 57.9555 14.0619C57.9555 14.2108 58.0179 14.3349 58.1428 14.4342C58.27 14.5334 58.4551 14.5831 58.6979 14.5831C58.9385 14.5831 59.1524 14.5365 59.3397 14.4435C59.5271 14.3484 59.6647 14.2191 59.7526 14.0557C59.8197 13.9296 59.8532 13.7435 59.8532 13.4974V13.2926Z" fill="white" />
    <path d="M62.032 14.946H61.4526V10.3984H62.0771V12.0208C62.3407 11.7251 62.6773 11.5772 63.0866 11.5772C63.3133 11.5772 63.5273 11.6186 63.7285 11.7013C63.932 11.7819 64.0985 11.8967 64.2281 12.0456C64.3599 12.1924 64.4628 12.3703 64.5368 12.5792C64.6108 12.788 64.6479 13.0114 64.6479 13.2492C64.6479 13.8138 64.4917 14.2501 64.1795 14.5583C63.8673 14.8664 63.4926 15.0205 63.0554 15.0205C62.6206 15.0205 62.2794 14.8581 62.032 14.5334V14.946ZM62.025 13.274C62.025 13.669 62.0852 13.9544 62.2054 14.1302C62.402 14.4176 62.668 14.5614 63.0034 14.5614C63.2763 14.5614 63.5122 14.4559 63.7111 14.245C63.91 14.0319 64.0095 13.7155 64.0095 13.2957C64.0095 12.8656 63.9135 12.5481 63.7215 12.3434C63.5319 12.1387 63.3017 12.0363 63.0311 12.0363C62.7582 12.0363 62.5223 12.1428 62.3234 12.3558C62.1245 12.5668 62.025 12.8728 62.025 13.274Z" fill="white" />
    <path d="M65.3972 14.946V10.3984H66.0217V14.946H65.3972Z" fill="white" />
    <path d="M69.5154 13.8851L70.1607 13.9565C70.0589 14.2936 69.8704 14.5552 69.5952 14.7413C69.3199 14.9274 68.9684 15.0205 68.5405 15.0205C68.0016 15.0205 67.5737 14.8726 67.2568 14.5769C66.9423 14.2791 66.785 13.8624 66.785 13.3268C66.785 12.7725 66.9446 12.3424 67.2638 12.0363C67.5829 11.7302 67.9969 11.5772 68.5058 11.5772C68.9984 11.5772 69.4009 11.7271 69.7131 12.027C70.0254 12.3269 70.1815 12.7487 70.1815 13.2926C70.1815 13.3257 70.1803 13.3754 70.178 13.4415H67.4303C67.4534 13.8034 67.5679 14.0805 67.7737 14.2729C67.9796 14.4652 68.2363 14.5614 68.5439 14.5614C68.7729 14.5614 68.9684 14.5076 69.1303 14.4001C69.2922 14.2925 69.4205 14.1209 69.5154 13.8851ZM67.465 12.9824H69.5223C69.4945 12.7053 69.4159 12.4975 69.2864 12.3589C69.0875 12.1438 68.8296 12.0363 68.5127 12.0363C68.2259 12.0363 67.9842 12.1221 67.7876 12.2938C67.5933 12.4654 67.4858 12.695 67.465 12.9824Z" fill="white" />
    <path d="M72.6933 13.2988C72.6933 12.6888 72.883 12.2369 73.2623 11.9432C73.5791 11.6992 73.9654 11.5772 74.421 11.5772C74.9276 11.5772 75.3416 11.7261 75.6631 12.0239C75.9846 12.3196 76.1453 12.7291 76.1453 13.2523C76.1453 13.6762 76.0736 14.0102 75.9302 14.2543C75.7891 14.4962 75.5821 14.6844 75.3092 14.8188C75.0386 14.9533 74.7425 15.0205 74.421 15.0205C73.9053 15.0205 73.4878 14.8726 73.1686 14.5769C72.8517 14.2811 72.6933 13.8551 72.6933 13.2988ZM73.3351 13.2988C73.3351 13.7207 73.438 14.0371 73.6439 14.2481C73.8497 14.4569 74.1088 14.5614 74.421 14.5614C74.731 14.5614 74.9889 14.4559 75.1947 14.245C75.4005 14.034 75.5035 13.7124 75.5035 13.2802C75.5035 12.8728 75.3994 12.5647 75.1912 12.3558C74.9854 12.1449 74.7286 12.0394 74.421 12.0394C74.1088 12.0394 73.8497 12.1438 73.6439 12.3527C73.438 12.5616 73.3351 12.877 73.3351 13.2988Z" fill="white" />
    <path d="M76.8808 14.946V11.6517H77.4428V12.1201C77.7135 11.7582 78.1043 11.5772 78.6155 11.5772C78.8375 11.5772 79.0411 11.6134 79.2261 11.6858C79.4134 11.7561 79.5534 11.8492 79.6459 11.965C79.7384 12.0808 79.8032 12.2183 79.8402 12.3775C79.8633 12.4809 79.8749 12.6619 79.8749 12.9204V14.946H79.2504V12.9421C79.2504 12.7146 79.2261 12.545 79.1775 12.4334C79.129 12.3196 79.0422 12.2297 78.9173 12.1635C78.7947 12.0952 78.6502 12.0611 78.4837 12.0611C78.2177 12.0611 77.9875 12.1366 77.7932 12.2876C77.6013 12.4385 77.5053 12.725 77.5053 13.1468V14.946H76.8808Z" fill="white" />
    <path d="M51.6788 23.0395H46.7947V24.2912H50.381C50.1298 25.8559 48.9297 27.048 46.9063 27.048C44.5759 27.048 43.027 25.2598 43.027 22.9948C43.027 20.7149 44.5899 18.9267 46.8505 18.9267C48.232 18.9267 49.4182 19.6867 49.9763 20.8043L51.0927 20.1039C50.2833 18.6138 48.6925 17.5856 46.8645 17.5856C43.8922 17.5856 41.7432 19.9996 41.7432 22.9948C41.7432 25.9751 43.8782 28.404 46.8924 28.404C49.8368 28.404 51.6788 26.3178 51.6788 23.6654V23.0395Z" fill="white" />
    <path d="M58.9084 20.7596V22.0411C58.3502 21.1321 57.4572 20.5659 56.271 20.5659C54.3313 20.5659 52.7405 22.2795 52.7405 24.485C52.7405 26.6904 54.3313 28.404 56.271 28.404C57.4572 28.404 58.3502 27.8378 58.9084 26.9288V28.2103H60.1225V20.7596H58.9084ZM56.4245 27.1523C55.0291 27.1523 53.9546 26.0049 53.9546 24.485C53.9546 22.965 55.0291 21.8176 56.4245 21.8176C57.8339 21.8176 58.9084 22.965 58.9084 24.485C58.9084 26.0049 57.8339 27.1523 56.4245 27.1523Z" fill="white" />
    <path d="M61.9619 28.2103H63.1759V17.3323H61.9619V28.2103Z" fill="white" />
    <path d="M70.8052 20.7596V22.0411C70.247 21.1321 69.3539 20.5659 68.1678 20.5659C66.2281 20.5659 64.6373 22.2795 64.6373 24.485C64.6373 26.6904 66.2281 28.404 68.1678 28.404C69.3539 28.404 70.247 27.8378 70.8052 26.9288V28.2103H72.0192V20.7596H70.8052ZM68.3213 27.1523C66.9258 27.1523 65.8513 26.0049 65.8513 24.485C65.8513 22.965 66.9258 21.8176 68.3213 21.8176C69.7307 21.8176 70.8052 22.965 70.8052 24.485C70.8052 26.0049 69.7307 27.1523 68.3213 27.1523Z" fill="white" />
    <path d="M79.9149 28.2103L77.2496 24.3657L79.7614 20.7596H78.338L76.5379 23.3524L74.7378 20.7596H73.3004L75.8262 24.3806L73.1469 28.2103H74.5703L76.5379 25.3939L78.4915 28.2103H79.9149Z" fill="white" />
    <path d="M85.9104 20.7596L83.901 26.601L81.5287 20.7596H80.2309L83.273 28.1805L83.1474 28.4636C82.7846 29.4471 82.1985 30.0581 81.2636 29.9836V31.1906C82.6869 31.2949 83.7754 30.3412 84.3615 28.6723L87.2082 20.7596H85.9104Z" fill="white" />
    <path d="M94.9899 28.404C96.9854 28.404 98.3669 27.2417 98.3669 25.4088C98.3669 23.3972 96.7621 22.8607 95.0597 22.2646C93.6921 21.8027 93.0084 21.4451 93.0084 20.4765C93.0084 19.5228 93.734 18.9267 94.7806 18.9267C95.9667 18.9267 96.6505 19.642 96.9994 20.5361L98.0878 19.8655C97.5436 18.5542 96.3854 17.5856 94.7806 17.5856C93.1479 17.5856 91.7106 18.6585 91.7106 20.5361C91.7106 22.4733 93.2177 22.9948 94.7806 23.5313C96.2179 24.0081 97.0831 24.3657 97.0831 25.4386C97.0831 26.3774 96.3993 27.048 95.0178 27.048C93.5945 27.048 92.7572 26.3029 92.4083 25.1555L91.292 25.841C91.8083 27.4056 93.134 28.404 94.9899 28.404Z" fill="white" />
    <path d="M103.369 22.0113V20.7596H101.513V18.6734L100.299 19.0608V20.7596H98.9178V22.0113H100.299V26.0496C100.299 27.9123 101.276 28.5083 103.369 28.2103V27.048C102.127 27.1076 101.513 27.1821 101.513 26.0496V22.0113H103.369Z" fill="white" />
    <path d="M107.732 28.404C109.769 28.404 111.416 26.6904 111.416 24.485C111.416 22.2795 109.769 20.5659 107.732 20.5659C105.694 20.5659 104.062 22.2795 104.062 24.485C104.062 26.6904 105.694 28.404 107.732 28.404ZM107.732 27.1374C106.35 27.1374 105.276 25.99 105.276 24.485C105.276 22.9799 106.35 21.8325 107.732 21.8325C109.127 21.8325 110.202 22.9799 110.202 24.485C110.202 25.99 109.127 27.1374 107.732 27.1374Z" fill="white" />
    <path d="M114.061 22.0113V20.7596H112.847V28.2103H114.061V24.2614C114.061 22.5031 115.191 21.9368 116.238 21.9815V20.6255C115.359 20.6255 114.507 20.9682 114.061 22.0113Z" fill="white" />
    <path d="M117.895 25.081H123.742C123.77 24.8873 123.784 24.6936 123.784 24.4999C123.784 22.4137 122.417 20.5659 120.309 20.5659C118.133 20.5659 116.653 22.2795 116.653 24.485C116.653 26.7202 118.146 28.404 120.393 28.404C121.789 28.404 122.849 27.7335 123.463 26.7202L122.431 26.0943C122.082 26.7053 121.384 27.1821 120.421 27.1821C119.123 27.1821 118.133 26.4221 117.895 25.081ZM120.309 21.7878C121.356 21.7878 122.347 22.4733 122.556 23.9485H117.895C118.091 22.6521 118.998 21.7878 120.309 21.7878Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7616 16.7217H19.6522C19.6522 11.8669 26.616 11.8333 26.616 16.7217H29.5066C29.5066 17.9794 28.9071 24.3202 28.3496 25.1474C27.9227 25.7811 27.0126 26.4344 26.3532 26.4344H19.915C19.2025 26.4344 18.3213 25.6762 17.9956 25.2018C17.4813 24.4526 17.5105 23.6293 17.3967 22.5187L16.7616 16.7217ZM11.2432 19.7405C11.2432 23.4694 11.8732 26.6211 13.6354 28.6386C13.9828 29.0363 13.8605 28.9624 14.2794 29.3079C15.2784 30.1318 15.8115 30.3501 17.0605 30.861C19.8202 31.9899 26.0453 31.9538 28.935 30.9823C30.258 30.5375 31.4023 29.9529 32.302 29.0956C35.063 26.4649 35.0251 22.3185 35.0251 18.5592C35.0251 11.553 31.4996 7.92773 23.9882 7.92773C21.7022 7.92773 19.5784 7.94307 17.4661 8.6315C16.6527 8.89658 16.1067 9.1332 15.4533 9.50831C12.1829 11.3854 11.2432 15.2558 11.2432 19.7405Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.0975 16.7217H25.1707C25.1707 15.5761 24.3523 14.4904 23.3312 14.4904H23.1998C21.9283 14.4904 21.0975 15.4284 21.0975 16.7217Z" fill="white" />
  </svg>

};
