export const RuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5989_2494" maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
        <path d="M22 4H2C0.895431 4 0 4.89543 0 6V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V6C24 4.89543 23.1046 4 22 4Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5989_2494)">
        <path d="M2 4.5H22C22.8284 4.5 23.5 5.17157 23.5 6V18C23.5 18.8284 22.8284 19.5 22 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V6C0.5 5.17157 1.17157 4.5 2 4.5Z" fill="white" stroke="#F0F0F0" />
        <path d="M24 9.33337H0V14.6667H24V9.33337Z" fill="#0C47B7" />
        <path d="M24 14.6666H0V20H24V14.6666Z" fill="#E53B35" />
      </g>
    </svg>

  );
};
