import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Faq.module.scss'
import arrowup from '../../assets/images/icons/arrow-up.svg'
import arrowdown from '../../assets/images/icons/arrowdown.svg'
import list from '../../assets/images/icons/list.svg'
import { useTranslation } from 'react-i18next'
import telegram from '../../assets/images/social/telegram.svg'
import phone from '../../assets/images/social/phone.svg'
import { ChevronIcon } from '@/shared/icons'

interface OpenItemsState {
  [key: number]: boolean;
}

export const Faq = () => {
  const [openItems, setOpenItems] = useState<OpenItemsState>({});

  const { t } = useTranslation(['faq', 'main', "rockets_faq"])

  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
          const id = element.getAttribute('id')
          setOpenItems(prevState => ({
            ...prevState,
            [hash.slice(-1)]: true
          }))
        }
      }, 5)
    }
  }, [hash])

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  const faqData = [
    {
      id: 1,
      question: t('question1'),
      answer: `<div class="${styles.paragraph}">${t('answer1_text1')}</div>
      <div class="${styles.paragraph}">${t('answer1_text2')}</div>
      <div class="${styles.paragraph}">${t('answer1_text3')}</div>
      <div class="${styles.paragraph}">${t('answer1_text4')}</div>
      <div class="${styles.paragraph}">${t('answer1_text5')}</div>
      `,
      anchor: "question1"
    },
    {
      id: 2,
      question: t('question2'),
      answer: t('answer2'),
      anchor: "question2"
    },
    {
      id: 3,
      question: t('question6'),
      answer: `<div class="${styles.paragraph}">${t('answer6_text1')}</div>
      <div class="${styles.paragraph}">${t('answer6_text2')}</div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer6_text3')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer6_text4')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer6_text5')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer6_text6')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" class="${styles.listImg}" />${t('answer6_text7')}
      </div>
      `,
      anchor: "question3"
    },
    {
      id: 4,
      question: t('question3'),
      answer: `<div class="${styles.paragraph}">${t('answer3_text1')}</div>
      ${t('answer3_text2')}
      `,
      anchor: "question4"
    },
    {
      id: 5,
      question: t('question4'),
      answer: `<div class="${styles.paragraph}">${t('answer4_text1')}</div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer4_text2')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer4_text3')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer4_text4')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('answer4_text5')}
      </div>
      `,
      anchor: "question5"
    },
    {
      id: 6,
      question: t('question5'),
      answer: t('answer5'),
      anchor: "question6"
    },
    {
      id: 7,
      question: t('question7'),
      answer: `<div class="${styles.paragraph}">${t('answer7_text1')}</div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer7_text2')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer7_text3')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer7_text4')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer7_text5')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer7_text6')}<a target="_blank" class="${styles.link}" href="https://help.steampowered.com/ru/faqs/view/451E-96B3-D194-50FC">${t('main:go')}</a>
        </div>
        <div class="${styles.paragraph} ${styles.listItemBig}">${t('answer7_text7')}</div>
      `,
      anchor: "question7"

    },
    {
      id: 8,
      question: t('question8'),
      answer: `<div class="${styles.paragraph}">${t('answer8_text1')}</div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer8_text2')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer8_text3')}
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />${t('answer8_text4')}<a target="_blank" class="${styles.link}" href="https://help.steampowered.com/ru/faqs/view/7EFD-3CAE-64D3-1C31">${t('main:go')}</a>
        </div>
        <div class="${styles.paragraph} ${styles.listItemBig}">${t('answer8_text5')}</div>
      `,
      anchor: "question8"
    },
    {
      id: 9,
      question: t('main:help'),
      answer: `<div class="${styles.paragraph}">${t('answer7')}</div>
      <div class="${styles.help}">
        <a class="${styles.tg}" href='${isRockets ? 'https://t.me/Bet_Assist_DD_Bot' : 'https://t.me/PMKzSupport_bot'}' target='_blank'>
          <img src=${telegram} alt="" />
        </a>
        ${isRockets ? `<a class="${styles.phone}" href='mailto:support@parik24.now' target='_blank'>
          support@parik24.now 
        </a>` : 
        `<a class="${styles.phone}" href='tel:9933' target='_blank'>
          <img src=${phone} alt="" />
          9933
        </a>`}
      </div>
      `,
      anchor: "question9"
    }
  ];

  const rocketsFaqData = [
    {
      id: 1,
      question: t('rockets_faq:question1'),
      answer: `<div class="${styles.paragraph}">${t('rockets_faq:answer1_text1')}</div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('rockets_faq:answer1_text2')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('rockets_faq:answer1_text3')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('rockets_faq:answer1_text4')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('rockets_faq:answer1_text5')}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t('rockets_faq:answer1_text6')}
      </div>
      `,
      anchor: "question1"
    },
    {
      id: 2,
      question: t('rockets_faq:question2'),
      answer: `<div class="${styles.paragraph}">${t('rockets_faq:answer2_text1')}</div>
      `,
      anchor: "question1"
    },
  ]

  const toggleAccordion = (id: number) => {
    setOpenItems((prevState: Record<number, boolean>) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <div className={`${styles.container} container-main`}>
      <div className={styles.title}>
        {t('faq_top')}
      </div>
      {(isRockets ? rocketsFaqData : faqData)
      
      .map((item, index) => (
        <div key={item.id} id={item.anchor} className={`${styles.item} ${isRockets && styles.rockets}`} >
          <div className={`${styles.question} ${openItems[item.id] ? `${styles.active}` : ''}`}
            onClick={() => toggleAccordion(item.id)}>

            <div>
              {item.question}
            </div>
            {isRockets && <div className={`${styles.arrow} ${openItems[item.id] ? `${styles.active}` : ''}`}>
              <ChevronIcon rotate={openItems[item.id] ? '270' : '90'} />
            </div>}
          </div>

          <div className={`${styles.answer} ${isRockets && styles.rockets} ${ isRockets ? (openItems[item.id] ? `${styles.open}` : '')  : styles.open}`}>
            <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
          </div>
        </div>
      ))}
    </div>
  )
}
