import styles from './Toast.module.scss'
import { CheckCircle, ErrorCircle, WarningCircle } from '@/shared/icons';

interface ToastProps {
  message: string;
  status: 'warning' | 'error' | 'success';
}

export const Toast = ({ message, status }: ToastProps) => {
  return (
    <div className={`${styles.container}
                     ${status === 'warning' ? styles.warning :
                       status === 'error' ? styles.error : ''}`}>
      <div className={styles.icon}>
        {
          status === 'warning' ?
          <WarningCircle /> :
          status === 'error' ?
          <ErrorCircle /> :
          <CheckCircle />
        }
      </div>
      <div className={styles.message}>
        {message}
      </div>
    </div>
  )
}
