export const Routes = {
  HOME: "/",
  SHOP: "/shop",
  QUESTS: "/quests",
  TOURNAMENTS: "/tournaments",
  HELP: "/help",
  FAQ: "/faq",
  WELCOME: "/welcome",
  PROFILE: "/profile",
  CYBERMARATHON: "/cybermarathon"
};