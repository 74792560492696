import axios from "axios";
import { useDispatch } from "react-redux";
import { updateAuth } from "@store/slices/auth/authSlice";

export const useOnboardingPassed = () => {
  const dispatch = useDispatch()

  const markOnboardingPassed = async () => {
    const token = localStorage.getItem('auth_token')
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile`,
        {
          onboarding_passed: true,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );

      if (response.data) {
        dispatch(
          updateAuth({
            isAuthenticated: true,
            user: response.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { markOnboardingPassed };
}
