import styles from "./TournamentsTab.module.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useRef } from 'react';
import { ChevronIcon } from "@/shared/icons";
import light from "@assets/images/cybermarathon/light.png";
import activeLight from "@assets/images/cybermarathon/light-active.png";
import completedLight from "@assets/images/cybermarathon/light-completed.png";

const COMPLETED = "Completed";
const REWARD_RECEIVED = "Reward received";

interface Tournament {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  logo?: string;
  status?: string;
}

export const TournamentsTab = ({ tournaments }: { tournaments: Tournament[] }) => {
  const swiperRef = useRef<any>(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const formatDate = (date: string): string => {
    if (!date) return '';

    const parts = date.split('.');
    if (parts.length >= 2) {
      return `${parts[0]}.${parts[1]}`;
    }

    return date;
  };

  const isActiveTournament = (startDate: string, endDate: string): boolean => {
    if (!startDate || !endDate) return false;

    const today = new Date();
    const [startDay, startMonth, startYear] = startDate.split('.').map(Number);
    const [endDay, endMonth, endYear] = endDate.split('.').map(Number);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    return today >= start && today <= end;
  };

  const getTournamentClass = (tournament: Tournament): string => {
    const classes = [styles.tournament];
    
    if (isActiveTournament(tournament.startDate, tournament.endDate)) {
      classes.push(styles.active);
    }
    
    if (tournament.status) {
      if (tournament.status === COMPLETED || tournament.status === REWARD_RECEIVED) {
        classes.push(styles.completed);
      }
    }
    
    return classes.join(' ');
  };

  const getTournamentStatusImg = (tournament: Tournament): string => {
    if (tournament.status === COMPLETED || tournament.status === REWARD_RECEIVED) {
      return completedLight;
    }

    if (isActiveTournament(tournament.startDate, tournament.endDate)) {
      return activeLight;
    }

    return light;
  };

  return (
    <div className={styles.container}>
      <div className={styles.customNavigation}>
        <button onClick={handlePrev} className={styles.prevButton}>
          <ChevronIcon />
        </button>
        <button onClick={handleNext} className={styles.nextButton}>
          <ChevronIcon />
        </button>
      </div>

      <div className={styles.tournamentsWrapper}>

        <Swiper
          spaceBetween={20}
          slidesPerView={8}
          navigation
          className={styles.swiper}
          ref={swiperRef}
          breakpoints={{
            300: {
              slidesPerView: 2
            },
            600: {
              slidesPerView: 3
            },
            800: {
              slidesPerView: 4
            },
            1000: {
              slidesPerView: 5
            },
            1200: {
              slidesPerView: 6
            },
            1440: {
              slidesPerView: 7
            },
            1600: {
              slidesPerView: 8
            },
            1800: {
              slidesPerView: 9
            },
            2000: {
              slidesPerView: 10
            }
          }}
        >
          {tournaments.map((tournament) => (
            <SwiperSlide key={tournament.id}>
              <div className={getTournamentClass(tournament)}>
                <div className={styles.tournamentLogo}>
                  <img
                    src={tournament.logo}
                    alt={tournament.name}
                  />
                </div>
                <div className={styles.tournamentName}>
                  {tournament.name}
                </div>
                <div className={styles.tournamentDates}>
                  {formatDate(tournament.startDate)} — {formatDate(tournament.endDate)}
                </div>

                <div className={styles.tournamentStatus}>
                  <img src={getTournamentStatusImg(tournament)} alt="" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </div>
  );
};