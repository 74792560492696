import { logoutSuccess } from "@/store";
import axios from "axios";
import { useDispatch } from "react-redux";

export const useLogout = () => {
  const dispatch = useDispatch();

  const logout = async () => {
    const token = localStorage.getItem('auth_token')

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/logout`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(token ? { Authorization: `Bearer ${token}` } : {})
        }
      })

      if (response.data.status === 'success') {
        dispatch(logoutSuccess())
      }

    } catch (error) {
      console.error(error);
    }
  }

  return { logout }
}