import styles from './Account.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalName, Toast } from '@/shared/components'
import { useTranslation } from 'react-i18next'
import profile from '@/assets/images/profile.png'
import { logoutSuccess, RootState } from '@/store'
import { ProfileIcon, LogoutIcon, CopyIcon, SteamIcon, PmPointIcon } from '@/shared/icons'
import { useModals } from '@/shared/contexts'
import axios from 'axios'


export const Account = () => {
  const userInfo = useSelector((state: RootState) => state.auth.user)

  const { t } = useTranslation(['profile', 'notifications', 'main'])

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text)

    if (type === 'account') {
      toast(<Toast message={t('notifications:account_copied')} status="success" />, {
        hideProgressBar: true
      })
    } else if (type === 'steam') {
      toast(<Toast message={t('notifications:steam_copied')} status="success" />, {
        hideProgressBar: true
      })
    }
  };

  const { openModal } = useModals()

  const dispatch = useDispatch()

  const logout = async () => {
    document.body.style.overflow = 'auto'
    const token = localStorage.getItem('auth_token')

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/logout`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(token ? { Authorization: `Bearer ${token}` } : {})
        }
      })

      if (response.data.status === 'success') {
        dispatch(logoutSuccess())
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleLogout = () => {
    openModal(ModalName.CONFIRM, {
      title: 'Выход',
      message: t('main:confirm_quite'),
      onConfirm: () => {
        logout()
      }
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.account} style={{ backgroundImage: `url(${profile})` }}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <ProfileIcon />
              <div>
                Профиль
              </div>
            </div>
            <div className={styles.headerRight} onClick={handleLogout}>
              <LogoutIcon />
            </div>
          </div>

          <div className={styles.main}>
            <div className={styles.name}>
              <div>
                Account ID:
              </div>
              <div>
                <div className={styles.id}>
                  {userInfo?.pm_id}
                </div>
              </div>
              <div className={styles.copy}
                onClick={() => userInfo?.pm_id && copyToClipboard(userInfo.pm_id, 'account')}>
                <CopyIcon />
              </div>
            </div>

            <div className={styles.address}>
              {userInfo?.phone}
            </div>

            <div className={styles.infoItem}>
              <div className={styles.infoItemTitle}>
                <div>
                  <SteamIcon />
                </div>
                <div>
                  <div className={styles.steam}>
                    Steam ID
                  </div>
                </div>
              </div>
              <div>
                {userInfo?.steam_id ? userInfo?.steam_id : '-'}
              </div>
            </div>

            <div className={styles.infoItem}>
              <div className={styles.infoItemTitle}>
                <div>
                  <PmPointIcon />
                </div>
                <div>
                  <div className={styles.steam}>
                    {t('current_balance')}
                  </div>
                </div>
              </div>
              <div>
                {userInfo?.pm_points} PM баллов
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
