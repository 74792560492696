import React from 'react';
import styles from './Modal.module.scss';
import { ChevronIcon, CloseIcon } from '@/shared/icons';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
  showBackButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, showCloseButton = true, showBackButton = false }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {children}

        {showBackButton && (
          <div className={styles.back}>
            <ChevronIcon />
          </div>
        )}

        {showCloseButton && (
          <div className={styles.close} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  );
};