import { Outlet } from "react-router-dom";
import { Footer, Header, ModalContainer } from "@shared/components";
import styles from "./layout.module.scss";
export const Layout = () => {
  const isRockets = process.env.REACT_APP_BRAND === 'rockets';
  return (
    <>
      <Header />
      <Outlet />
      <ModalContainer />

      {!isRockets ? <Footer /> : <div className={styles.footer}></div>}
    </>
  );
};
