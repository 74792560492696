import { NavLink, useParams } from "react-router-dom";
import styles from "./Tournament.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dota from "../../../assets/images/dota.svg";
import cs from "../../../assets/images/cs.svg";
import { Button } from "@/shared/components";
import { BackButton } from "@/shared/components";

const SPREADSHEET_ID = "1G8zbSbpvlUYmK04TfiF0Bjjgj013dxyMdstZLF0WBQY";
const SHEET_ID = "Лист1";
const API_KEY = "AIzaSyCJ-OwS_CHE1aIcz4osT4bPa1Q6Mc2AolE";

const Tournament = () => {
  const { id } = useParams();

  const [events, setEvents] = useState([]);

  const { t } = useTranslation(["main", "notifications"]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_ID}?alt=json&key=${API_KEY}`;

      try {
        const response = await fetch(url);
        const result = await response.json();

        result.values.shift();

        const parsedEvents = result.values.map((event) => {
          const [dayStart, monthStart, yearStart] =
            event[0]?.trim().split(".") || [];
          const [dayEnd, monthEnd, yearEnd] = event[1]?.trim().split(".") || [];

          const startDate =
            dayStart && monthStart && yearStart
              ? new Date(`${yearStart}-${monthStart}-${dayStart}`)
              : null;

          const endDate =
            dayEnd && monthEnd && yearEnd
              ? new Date(`${yearEnd}-${monthEnd}-${dayEnd}`)
              : null;

          return {
            startDate,
            endDate,
            name: event[2]?.trim(),
            gameType: event[3]?.trim(),
            prize: event[4]?.trim(),
            description: event[5]?.trim(),
            img: event[6]?.trim(),
            url: event[7]?.trim(),
            imgQuest: event[8]?.trim(), // Image quest
            nameQuest: event[9]?.trim(), // Name quest
            statusQuest: event[10]?.trim(), // Status
            descriptionOne: event[11]?.trim(), // Description_one
            descriptionTwo: event[12]?.trim(), // Description_two
            tegQuest: event[13]?.trim(), // Teg
            buttonUrl: event[14]?.trim(), // Botten_url
          };
        });

        setEvents(parsedEvents);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    if (isNaN(date)) return null;

    return date.toLocaleDateString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div
      className={styles.background}
      style={{ backgroundImage: `url(${events[id]?.img})` }}
    >
      <div className={styles.background_inner}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.info}>
              <div className={styles.title}>Турнир {events[id]?.name}</div>
              <div className={styles.modalGame}>
                {events[id]?.gameType === "Dota 2" ? (
                  <div className={styles.modalGameItem}>
                    <div>
                      <img src={dota} alt="Dota 2" />
                    </div>
                    <div>Dota 2</div>
                  </div>
                ) : events[id]?.gameType === "CS 2" ? (
                  <div
                    className={`${styles.modalGameItem} ${styles.modalGameItemCS}`}
                  >
                    <div>
                      <img src={cs} alt="CS 2" />
                    </div>
                    <div>CS 2</div>
                  </div>
                ) : events[id]?.gameType === "LoL" ? (
                  <div
                    className={`${styles.modalGameItem} ${styles.modalGameItemLoL}`}
                  >
                    <div>{/* <img src={LoL} alt="League of Legends" /> */}</div>
                    <div>League of Legends</div>
                  </div>
                ) : (
                  <div className={styles.modalGameItem}>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>

              <div className={styles.dates}>
                <div>
                  <div>Дата начала:</div>
                  <div className={styles.date}>
                    {formatDate(events[id]?.startDate)}
                  </div>
                </div>
                <div>
                  <div>Дата окончания:</div>
                  <div className={styles.date}>
                    {formatDate(events[id]?.endDate)}
                  </div>
                </div>
              </div>

              <div className={styles.description}>
                {events[id]?.description}
              </div>

              {events[id]?.url ? (
                <NavLink
                  to={events[id]?.url}
                  target="_blank"
                  className={styles.remove_events}
                >
                  <Button title="Перейти к матчам" />
                </NavLink>
              ) : (
                <Button title="Нет матчей" color="grey" />
              )}
            </div>
          </div>
        </div>
        {events[id]?.nameQuest ? (
          <div className={styles.quest_container}>
            <div className={styles.quest_wrapper}>
              <div className={styles.quest_inner}>
                <div>
                  <img
                    src={events[id]?.imgQuest}
                    className={styles.quest_inner_img}
                    alt="Quest Image"
                  />
                </div>

                <div className={styles.quest_inner_name}>
                  <div className={styles.quest_inner_name_up}>
                    {events[id]?.nameQuest}
                  </div>
                  <div className={styles.quest_inner_name_status}>
                    {events[id]?.statusQuest}
                  </div>
                </div>

                <div>
                  <svg
                    width="auto"
                    height="2"
                    viewBox="0 0 338 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1H337"
                      stroke="#DCDCDC"
                      stroke-opacity="0.1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className={styles.quest_inner_desc_block}>
                  <div className={styles.quest_inner_desc_block_name_up}>
                    {events[id]?.descriptionOne}
                  </div>
                  <div className={styles.quest_inner_desc_block_name_down}>
                    {events[id]?.descriptionTwo}
                  </div>

                  <div className={styles.quest_inner_desc_block_chips}>
                    <div className={styles.quest_inner_desc_block_chips_up}>
                      Parimatch
                    </div>

                    <div className={styles.quest_inner_desc_block_chips_down}>
                      {events[id]?.tegQuest}
                    </div>
                  </div>
                </div>

                <div className={styles.quest_inner_button}>
                  <a
                    href={events[id]?.buttonUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className={styles.quest_inner_button_btn}>
                      {t("begin_quest")}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Tournament;
