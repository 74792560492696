import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "@shared/layout";
import { PrivateRoute } from "./PrivateRoute";
import {
  Home,
  Shop,
  Quests,
  Profile,
  Welcome,
  Help,
  Faq,
  Tournaments,
  Tournament,
  Cybermarathon
} from "@/pages";
import HomeRockets from "@/pages/HomeRockets/HomeRockets";
import { useOttAuthorization } from "@/shared/hooks";

export const AppRoutes: React.FC = () => {
  useOttAuthorization()

  if (process.env.REACT_APP_BRAND === 'rockets') {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomeRockets />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/quests"
            element={
                <Quests />
            }
          />
          <Route
            path="/welcome"
            element={
              <PrivateRoute>
                <Welcome />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/quests" element={<Quests />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/tournament/:id" element={<Tournament />} />
        <Route path="/help" element={<Help />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/cybermarathon" element={<Cybermarathon />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <PrivateRoute>
              <Welcome />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  )
}
