export const ParimatchIcon = () => {
  return (
    <svg
      width="120"
      height="36"
      viewBox="0 0 120 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2416 4.69563L30.1202 8.29565H24.5127L25.6342 4.69563H31.2416ZM22.1095 16.9043L23.3912 12.3652H29.4793C32.6835 12.3652 34.6061 10.9565 35.5674 7.98257L36.048 6.26086C37.1695 2.50435 35.4072 0 31.2416 0H4.96663L0 16.7478H22.1095V16.9043Z"
        fill="#F2F740"
      />
      <path
        d="M45.3402 4.69564H47.423V10.0173H42.2959L45.3402 4.69564ZM38.2906 16.9043L39.8927 14.0869H47.423V16.9043H52.3892V0.156494H42.7764L32.6831 16.9043H38.2906Z"
        fill="#F2F740"
      />
      <path
        d="M62.4824 4.69564H67.9301L66.9686 8.2956H61.3611L62.4824 4.69564ZM72.4158 7.98258L72.8963 6.41735C74.0181 2.50431 72.2555 0.156494 68.0899 0.156494H58.7978L53.8311 16.9043H58.9581L60.2397 12.3652H63.4438L64.8858 16.9043H70.1727L68.5709 12.0521C70.4934 11.4261 71.775 10.1739 72.4158 7.98258Z"
        fill="#F2F740"
      />
      <path
        d="M77.2232 16.9043L82.1895 0.156494H77.0629L72.0962 16.9043H77.2232Z"
        fill="#F2F740"
      />
      <path
        d="M45.1808 19.2522L39.2527 25.9826L37.0097 19.2522H14.74L9.93359 36H32.0431L34.7667 26.7653L36.529 31.9304H39.7333L44.5399 26.4522L41.8158 36H46.9429L51.7493 19.2522H45.1808Z"
        fill="#F2F740"
      />
      <path
        d="M58.1572 29.1131L61.2015 23.7913H63.2843V29.1131H58.1572ZM58.6382 19.2522L48.5444 36H54.3123L55.9146 33.1826H63.4446V36H68.4108V19.2522H58.6382Z"
        fill="#F2F740"
      />
      <path
        d="M85.7135 24.1044L86.9952 19.2522H71.4547L70.0127 24.1044H75.6202L72.2558 36H77.3824L80.7468 24.1044H85.7135Z"
        fill="#F2F740"
      />
      <path
        d="M92.4435 24.1044H100.294L101.736 19.2522H93.405C90.2009 19.2522 88.2779 20.6609 87.3169 23.6348L85.5543 29.7392C84.433 33.6522 86.1951 36 90.3607 36H96.7694L98.2114 31.1479H90.3607L92.4435 24.1044Z"
        fill="#F2F740"
      />
      <path
        d="M114.873 19.2522L113.111 25.3566H107.343L109.105 19.2522H103.979L99.0122 36H104.139L105.901 30.0522H111.829L110.067 36H115.194L120 19.2522H114.873Z"
        fill="#F2F740"
      />
    </svg>
  )
}