import { useState, useRef, useEffect } from 'react';
import { DropdownOption, DropdownType } from './types';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  options: DropdownOption[];
  value: string | number;
  onChange: (value: string | number) => void;
  type?: DropdownType;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const Dropdown = ({
  options,
  value,
  onChange,
  type = 'select',
  placeholder = 'Select option',
  className = '',
  disabled = false,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find(option => option.value === value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option: DropdownOption) => {
    onChange(option.value);
    if (type === 'select') {
      setIsOpen(false);
    }
  };

  return (
    <div className={`${styles.dropdown} ${className}`} ref={dropdownRef}>
      <div 
        className={`${styles.selected} ${isOpen ? styles.open : ''}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {selectedOption?.icon && selectedOption.icon}
        <span>{selectedOption?.label}</span>
      </div>

      {isOpen && !disabled && (
        <div className={styles.options}>
          {options.map((option) => (
            <div
              key={option.value}
              className={`${styles.option} ${option.value === value ? styles.active : ''}`}
              onClick={() => handleSelect(option)}
            >
              <div className={styles.content}>
                {option.icon && <div className={styles.icon}>{option.icon}</div>}
                <span>{option.label}</span>
              </div>
              <div className={`${styles.radio} ${option.value === value ? styles.checked : ''}`} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}; 