import { Modal, Button } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './ConfirmModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export interface ConfirmModalData {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  variant?: 'warning' | 'info' | 'success' | 'error';
}

export const ConfirmModal = () => {
  const { isModalOpen, closeModal, getModalData } = useModals();
  const { t } = useTranslation(["main", "help", "welcome"]);

  const confirmData = getModalData<ConfirmModalData>(ModalName.CONFIRM) || {};

  const {
    title = t("confirm_action"),
    message = t("are_you_sure"),
    confirmButtonText = "Выйти",
    cancelButtonText = "Закрыть",
    onConfirm,
    onCancel,
    variant = 'info'
  } = confirmData;

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    closeModal();
  }, [onConfirm, closeModal]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    closeModal();
  }, [onCancel, closeModal]);

  return (
    <Modal isOpen={isModalOpen(ModalName.CONFIRM)} onClose={closeModal}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.wrapper}>
        <div className={`${styles.message} ${styles[variant]}`}>
          {message}
        </div>

        <div className={styles.buttons}>
          <Button
            title={confirmButtonText}
            onClick={handleConfirm}
          />
          <Button
            title={cancelButtonText}
            onClick={handleCancel}
            variant="dark"
          />
        </div>
      </div>
    </Modal>
  );
};