import { loginSuccess } from "@/store/slices/auth/authSlice";
import { LoginResponse } from "@/store/slices/auth/types";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogout } from "./use-logout";

export const useOttAuthorization = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ott = urlParams.get('ott')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logout } = useLogout()
  const handleOttAuthorization = async () => {
    const response = await axios.post<LoginResponse>(`${process.env.REACT_APP_API_URL}/login_by_ott`, {
      ott
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })

   
    if (response.data.status === 'success') {
      dispatch(loginSuccess({
        isAuthenticated: true,
        user: response.data.userInfo
      }))

      localStorage.setItem('auth_token', response.data.meta.access_token)

      if (!response.data.userInfo.onboarding_passed) {
        navigate('/welcome')
      }
    }
  }

  useEffect(() => {
    const isIframe = window.self !== window.top;
    if (isIframe) {
      if (!ott) {
        logout()
      }
    }

    if (!ott) {
      return
    }
    handleOttAuthorization()
  }, [])
};