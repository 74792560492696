// TODO(gabdykalyk): refactor all queries and component
import styles from "./Quests.module.scss";
import Quest from "../../components/Quest/Quest";
import { useEffect, useState } from "react";
import arrow from "../../assets/images/icons/arrowup.svg";
import filter from "../../assets/images/icons/filter.svg";
import arrowDown from "../../assets/images/icons/arrow-down.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import loader from "../../assets/images/icons/loader.svg";
import { useSelector } from "react-redux";

const PER_PAGE = 8;

const Quests = () => {
  const [showLoader, setShowLoader] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { t } = useTranslation("main");

  const handleLoginClick = () => {
    
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const toggleStatus = () => {
    setIsStatusOpen(!isStatusOpen);
    setIsFilterOpen(false);
  };

  const selectStatus = (status) => {
    setSelectedStatus(status);
    setIsStatusOpen(false);
    fetchData(1, selectedFilter, status);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    setIsStatusOpen(false);
  };

  const selectFilter = (filter) => {
    setSelectedFilter(filter);
    setIsFilterOpen(false);
    fetchData(1, filter, selectedStatus);
  };

  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [categories, setCategories] = useState(null);

  const fetchData = async (page, category = null, status = null) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('auth_token')

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${
          isAuthenticated ? "my_quests" : "quests"
        }`,
        {
          params: {
            page: page,
            per_page: PER_PAGE,
            quest_category: category,
            status: status,
            cacheBuster: Math.random(),
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );
      if (response.data) {
        if (page === 1) {
          setProducts(response.data.data);
          setCategories(response.data.meta.categories);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...response.data.data,
          ]);
        }
        setPagination(response.data.pagination);
        setTotalItemsCount(response.data.pagination.total_count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, selectedFilter, selectedStatus);
  }, [isAuthenticated]);

  const displayedItemsCount = currentPage * PER_PAGE;

  const additionalItemsCount = totalItemsCount - displayedItemsCount;

  const next = () => {
    if (pagination && pagination.next_page) {
      setCurrentPage(currentPage + 1);
      fetchData(pagination.next_page);
    }
  };

  const [loading, setLoading] = useState(false);

  const setLoader = () => {
    setShowLoader(true);
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    setLoader();
  }, [selectedFilter, selectedStatus]);

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  return (
    <div>
      <div className={`${styles.container} container-main`}>
        {/* <BackButton /> */}
        <div className={styles.title}>
          {t("quests")}

          <div className={styles.categories}>
            <div className={`${styles.category} ${selectedFilter === null ? styles.active : ""}`} onClick={() => selectFilter(null)}>
              {t("all")}
            </div>
            <div className={`${styles.category} ${selectedFilter === "sport" ? styles.active : ""}`} onClick={() => selectFilter("sport")}>
              Sport
            </div>
          </div>
        </div>

        <div className={styles.wrapper}>
          {showLoader ? (
            <div className={styles.loaderContainer}>
              <div>
                {t("loading")}
                <div className={styles.loaderWrapper}>
                  <div className={styles.loader}></div>
                </div>
              </div>
            </div>
          ) : products.length ? (
            products.map((quest) => (
              <Quest
                key={quest.id}
                quest={quest}
                onLoginClick={handleLoginClick}
              />
            ))
          ) : (
            <div className={styles.empty}>
              <div>{t("nothing_here")}</div>
              <div
                className={styles.emptyInfo}
                dangerouslySetInnerHTML={{ __html: isRockets ? t("rockets_try_another_filters") : t("try_another_filters") }}
              ></div>
            </div>
          )}
        </div>
        <div className={styles.more}>
          {!showLoader && additionalItemsCount > 0 && (
            <div onClick={next} className={styles.btn}>
              <button disabled={loading}>
                {loading ? (
                  <img className={styles.loader} src={loader} alt="" />
                ) : (
                  t("more")
                )}
              </button>
            </div>
          )}

          {!showLoader && products.length ? (
            <div onClick={scrollToTop} className={styles.up}>
              <img src={arrow} alt="" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Quests;
