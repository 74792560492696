import { NavLink } from "react-router-dom";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import { ModalName, SocialLinks } from "@shared/components";
import { useModals } from "@shared/contexts/modal-context";
import { AndroidIcon, GalaxyIcon, GalleryIcon, IosIcon, TgIcon, TiktokIcon, YoutubeIcon } from "@/shared/icons";

export const Footer = () => {
  const { t } = useTranslation(["main"]);
  const { openModal } = useModals();

  const handleOpenRules = () => {
    openModal(ModalName.OFFER_RULES);
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} container-main`}>
        <div className={styles.social}>
          <div className={styles.right_mob}>
            <SocialLinks />
          </div>
          <div className={styles.left}>
            <div>
              <NavLink to="https://parimatch.onelink.me/hyhP/v2fqt0je" target="_blank">
                <AndroidIcon />
              </NavLink>
            </div>
            <div>
              <NavLink to="https://parimatch.onelink.me/hyhP/v2fqt0je" target="_blank">
                <GalleryIcon />
              </NavLink>
            </div>
            <div>
              <NavLink to="https://parimatch.onelink.me/hyhP/v2fqt0je" target="_blank">
                <GalaxyIcon />
              </NavLink>
            </div>
            <div>
              <NavLink to="https://parimatch.onelink.me/hyhP/v2fqt0je" target="_blank">
                <IosIcon />
              </NavLink>
            </div>
          </div>
          <div className={styles.right}>
            <SocialLinks />
          </div>
        </div>
        <div className={styles.license_offer}>
          <div className={styles.license}>{t("license")}</div>
          <button className={styles.offer} onClick={handleOpenRules}>
            {t("footer_offer")}
          </button>
        </div>
      </div>
    </div>
  );
};
