import { Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './HelpModal.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import telegram from '@assets/images/social/telegram.svg'
import phone from '@assets/images/social/phone.svg'

export const HelpModal = () => {
  const { isModalOpen, closeModal } = useModals();

  const { t } = useTranslation(['main', 'faq', 'help'])

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  return (
    <Modal isOpen={isModalOpen(ModalName.HELP)} onClose={closeModal}>
      <div className={styles.title}>
        {t('help')}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          {t('help:have_trouble')} <br />
          {t('help:ask_help')}
        </div>
        {
          isRockets ?
            <div className={styles.help}>
              <NavLink className={styles.tg}
                to={'https://t.me/Bet_Assist_DD_Bot'}
                target='_blank'>
                <img src={telegram} alt="" />

                @Bet_Assist_DD_Bot
              </NavLink>
              <NavLink className={styles.phone}
                to={'mailto:support@parik24.now '}>
                support@parik24.now 
              </NavLink>
            </div> :
            <div className={styles.help}>
              <NavLink className={styles.tg}
                to={'https://t.me/PMKzSupport_bot'}
                target='_blank'>
                <img src={telegram} alt="" />

                @PMKzSupport_bot
              </NavLink>
              <NavLink className={styles.phone}
                to={'tel:9933'}>
                <img src={phone} alt="" />
                9933
              </NavLink>
            </div>
        }
      </div>
    </Modal>
  )
}