export const AndroidIcon = () => {
  return <svg width="131" height="40" viewBox="0 0 131 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.36524C0 1.95439 1.96172 0 4.38162 0H126.191C128.61 0 130.572 1.95439 130.572 4.36524V34.922C130.572 37.3328 128.61 39.2872 126.191 39.2872H4.38162C1.96172 39.2872 0 37.3328 0 34.922V4.36524Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M126.191 0.873049H4.38162C2.4457 0.873049 0.876323 2.43656 0.876323 4.36524V34.922C0.876323 36.8506 2.4457 38.4142 4.38162 38.4142H126.191C128.126 38.4142 129.696 36.8506 129.696 34.922V4.36524C129.696 2.43656 128.126 0.873049 126.191 0.873049ZM4.38162 0C1.96172 0 0 1.95439 0 4.36524V34.922C0 37.3328 1.96172 39.2872 4.38162 39.2872H126.191C128.61 39.2872 130.572 37.3328 130.572 34.922V4.36524C130.572 1.95439 128.61 0 126.191 0H4.38162Z" fill="white" />
    <path d="M56.6201 14.9301V9.33948H58.3295C58.7154 9.33948 59.0099 9.36617 59.2129 9.41956C59.4973 9.49329 59.7399 9.62677 59.9407 9.81999C60.2025 10.0691 60.3977 10.3882 60.5263 10.7772C60.6572 11.1636 60.7226 11.606 60.7226 12.1043C60.7226 12.5289 60.6786 12.9051 60.5906 13.2331C60.5026 13.5611 60.3898 13.8331 60.2521 14.0492C60.1145 14.2628 59.9633 14.4318 59.7985 14.5564C59.6361 14.6785 59.4386 14.7712 59.2062 14.8348C58.976 14.8984 58.7109 14.9301 58.4107 14.9301H56.6201ZM57.2768 14.2704H58.3363C58.6635 14.2704 58.9196 14.2361 59.1046 14.1674C59.2919 14.0988 59.4409 14.0022 59.5514 13.8776C59.7071 13.7022 59.8279 13.467 59.9136 13.1721C60.0016 12.8746 60.0456 12.5149 60.0456 12.0929C60.0456 11.5081 59.9599 11.0594 59.7884 10.7467C59.6191 10.4314 59.4127 10.2204 59.1689 10.1136C58.9929 10.0374 58.7097 9.99922 58.3193 9.99922H57.2768V14.2704Z" fill="white" />
    <path d="M61.3251 12.9051C61.3251 12.1552 61.5102 11.5996 61.8802 11.2386C62.1894 10.9386 62.5662 10.7886 63.0108 10.7886C63.505 10.7886 63.9089 10.9717 64.2226 11.3378C64.5363 11.7013 64.6931 12.2047 64.6931 12.8479C64.6931 13.3691 64.6231 13.7797 64.4832 14.0797C64.3456 14.3772 64.1436 14.6085 63.8773 14.7738C63.6133 14.939 63.3245 15.0217 63.0108 15.0217C62.5076 15.0217 62.1003 14.8399 61.7888 14.4763C61.4797 14.1128 61.3251 13.589 61.3251 12.9051ZM61.9513 12.9051C61.9513 13.4238 62.0517 13.8128 62.2526 14.0721C62.4534 14.3289 62.7062 14.4573 63.0108 14.4573C63.3132 14.4573 63.5648 14.3276 63.7656 14.0683C63.9665 13.809 64.0669 13.4136 64.0669 12.8823C64.0669 12.3814 63.9653 12.0026 63.7622 11.7458C63.5614 11.4865 63.3109 11.3568 63.0108 11.3568C62.7062 11.3568 62.4534 11.4852 62.2526 11.742C62.0517 11.9988 61.9513 12.3865 61.9513 12.9051Z" fill="white" />
    <path d="M66.0741 14.9301L64.974 10.8802H65.6036L66.1757 13.2179L66.3889 14.0874C66.3979 14.0441 66.46 13.7657 66.5751 13.2522L67.1471 10.8802H67.7733L68.3115 13.2293L68.4909 14.0035L68.6974 13.2217L69.3135 10.8802H69.9058L68.782 14.9301H68.1491L67.577 12.5047L67.4382 11.8145L66.7105 14.9301H66.0741Z" fill="white" />
    <path d="M70.4203 14.9301V10.8802H70.9687V11.456C71.2327 11.0111 71.6141 10.7886 72.1128 10.7886C72.3294 10.7886 72.528 10.8331 72.7085 10.9221C72.8913 11.0085 73.0278 11.123 73.1181 11.2653C73.2084 11.4077 73.2715 11.5768 73.3076 11.7725C73.3302 11.8996 73.3415 12.1221 73.3415 12.4399V14.9301H72.7322V12.4666C72.7322 12.1869 72.7085 11.9785 72.6611 11.8412C72.6137 11.7013 72.5291 11.5907 72.4073 11.5094C72.2877 11.4255 72.1466 11.3835 71.9841 11.3835C71.7246 11.3835 71.5001 11.4763 71.3106 11.6619C71.1233 11.8475 71.0296 12.1996 71.0296 12.7183V14.9301H70.4203Z" fill="white" />
    <path d="M74.2656 14.9301V9.33948H74.8748V14.9301H74.2656Z" fill="white" />
    <path d="M75.5958 12.9051C75.5958 12.1552 75.7809 11.5996 76.151 11.2386C76.4601 10.9386 76.837 10.7886 77.2815 10.7886C77.7757 10.7886 78.1796 10.9717 78.4933 11.3378C78.807 11.7013 78.9638 12.2047 78.9638 12.8479C78.9638 13.3691 78.8938 13.7797 78.7539 14.0797C78.6163 14.3772 78.4143 14.6085 78.148 14.7738C77.884 14.939 77.5952 15.0217 77.2815 15.0217C76.7783 15.0217 76.371 14.8399 76.0596 14.4763C75.7504 14.1128 75.5958 13.589 75.5958 12.9051ZM76.222 12.9051C76.222 13.4238 76.3225 13.8128 76.5233 14.0721C76.7241 14.3289 76.9769 14.4573 77.2815 14.4573C77.5839 14.4573 77.8355 14.3276 78.0363 14.0683C78.2372 13.809 78.3376 13.4136 78.3376 12.8823C78.3376 12.3814 78.236 12.0026 78.033 11.7458C77.8321 11.4865 77.5816 11.3568 77.2815 11.3568C76.9769 11.3568 76.7241 11.4852 76.5233 11.742C76.3225 11.9988 76.222 12.3865 76.222 12.9051Z" fill="white" />
    <path d="M82.0271 14.4306C81.8015 14.6467 81.5837 14.7992 81.3738 14.8882C81.1662 14.9772 80.9428 15.0217 80.7036 15.0217C80.3087 15.0217 80.0052 14.9136 79.7931 14.6975C79.581 14.4789 79.4749 14.2005 79.4749 13.8624C79.4749 13.664 79.5144 13.4835 79.5934 13.3208C79.6746 13.1556 79.7796 13.0234 79.9082 12.9242C80.0391 12.8251 80.1857 12.7501 80.3482 12.6992C80.4678 12.6636 80.6483 12.6293 80.8898 12.5963C81.3817 12.5302 81.7439 12.4513 81.9764 12.3598C81.9786 12.2657 81.9797 12.206 81.9797 12.1806C81.9797 11.9009 81.9222 11.7039 81.8071 11.5895C81.6514 11.4344 81.4201 11.3568 81.1132 11.3568C80.8266 11.3568 80.6145 11.4141 80.4768 11.5285C80.3415 11.6403 80.241 11.8399 80.1756 12.1272L79.5798 12.0357C79.634 11.7484 79.7231 11.517 79.8473 11.3416C79.9714 11.1636 80.1508 11.0276 80.3855 10.9335C80.6201 10.8369 80.8921 10.7886 81.2012 10.7886C81.5081 10.7886 81.7575 10.8293 81.9493 10.9107C82.1411 10.992 82.2821 11.095 82.3724 11.2196C82.4627 11.3416 82.5258 11.4967 82.5619 11.6848C82.5823 11.8018 82.5924 12.0128 82.5924 12.3179V13.2331C82.5924 13.8712 82.6048 14.2755 82.6296 14.4458C82.6567 14.6136 82.7086 14.7751 82.7853 14.9301H82.149C82.0858 14.7878 82.0452 14.6212 82.0271 14.4306ZM81.9764 12.8975C81.7552 12.9992 81.4235 13.0857 80.9812 13.1568C80.7307 13.1975 80.5536 13.2433 80.4498 13.2941C80.346 13.345 80.2659 13.42 80.2094 13.5191C80.153 13.6157 80.1248 13.7238 80.1248 13.8433C80.1248 14.0263 80.1857 14.1789 80.3076 14.3009C80.4317 14.4229 80.6122 14.484 80.8492 14.484C81.0839 14.484 81.2926 14.4268 81.4754 14.3123C81.6582 14.1954 81.7924 14.0365 81.8782 13.8357C81.9436 13.6806 81.9764 13.4518 81.9764 13.1492V12.8975Z" fill="white" />
    <path d="M85.8724 14.9301V14.4191C85.6445 14.8208 85.3093 15.0217 84.8671 15.0217C84.5805 15.0217 84.3164 14.9327 84.075 14.7547C83.8358 14.5768 83.6496 14.3289 83.5165 14.0111C83.3856 13.6907 83.3202 13.3234 83.3202 12.909C83.3202 12.5047 83.38 12.1386 83.4996 11.8107C83.6192 11.4802 83.7986 11.2272 84.0378 11.0518C84.277 10.8763 84.5444 10.7886 84.84 10.7886C85.0566 10.7886 85.2495 10.8407 85.4188 10.945C85.588 11.0467 85.7257 11.1802 85.8317 11.3454V9.33948H86.4376V14.9301H85.8724ZM83.9464 12.909C83.9464 13.4276 84.0434 13.8153 84.2375 14.0721C84.4315 14.3289 84.6606 14.4573 84.9246 14.4573C85.1909 14.4573 85.4165 14.3352 85.6016 14.0912C85.7889 13.8446 85.8825 13.4696 85.8825 12.9662C85.8825 12.4119 85.7877 12.0052 85.5982 11.7458C85.4086 11.4865 85.1751 11.3568 84.8975 11.3568C84.6267 11.3568 84.3999 11.4814 84.2172 11.7306C84.0366 11.9797 83.9464 12.3725 83.9464 12.909Z" fill="white" />
    <path d="M89.4739 14.9301V11.4141H88.9357V10.8802H89.4739V10.4492C89.4739 10.1772 89.4953 9.97507 89.5382 9.84287C89.5969 9.6649 89.6996 9.52126 89.8462 9.41194C89.9952 9.30007 90.2028 9.24414 90.4691 9.24414C90.6406 9.24414 90.8301 9.26702 91.0377 9.31278L90.9463 9.91151C90.82 9.88609 90.7004 9.87338 90.5875 9.87338C90.4025 9.87338 90.2716 9.91787 90.1949 10.0069C90.1182 10.0958 90.0798 10.2624 90.0798 10.5064V10.8802H90.7805V11.4141H90.0798V14.9301H89.4739Z" fill="white" />
    <path d="M91.0309 12.9051C91.0309 12.1552 91.216 11.5996 91.5861 11.2386C91.8952 10.9386 92.2721 10.7886 92.7166 10.7886C93.2108 10.7886 93.6147 10.9717 93.9284 11.3378C94.2421 11.7013 94.3989 12.2047 94.3989 12.8479C94.3989 13.3691 94.329 13.7797 94.189 14.0797C94.0514 14.3772 93.8494 14.6085 93.5832 14.7738C93.3191 14.939 93.0303 15.0217 92.7166 15.0217C92.2134 15.0217 91.8061 14.8399 91.4947 14.4763C91.1855 14.1128 91.0309 13.589 91.0309 12.9051ZM91.6572 12.9051C91.6572 13.4238 91.7576 13.8128 91.9584 14.0721C92.1592 14.3289 92.412 14.4573 92.7166 14.4573C93.019 14.4573 93.2706 14.3276 93.4715 14.0683C93.6723 13.809 93.7727 13.4136 93.7727 12.8823C93.7727 12.3814 93.6712 12.0026 93.4681 11.7458C93.2672 11.4865 93.0168 11.3568 92.7166 11.3568C92.412 11.3568 92.1592 11.4852 91.9584 11.742C91.7576 11.9988 91.6572 12.3865 91.6572 12.9051Z" fill="white" />
    <path d="M95.1097 14.9301V10.8802H95.6581V11.4941C95.798 11.2068 95.9266 11.0174 96.044 10.9259C96.1636 10.8344 96.2945 10.7886 96.4366 10.7886C96.642 10.7886 96.8507 10.8624 97.0628 11.0098L96.853 11.6467C96.704 11.5475 96.5551 11.4979 96.4062 11.4979C96.273 11.4979 96.1534 11.5437 96.0474 11.6352C95.9413 11.7242 95.8657 11.8488 95.8206 12.009C95.7529 12.253 95.719 12.52 95.719 12.8098V14.9301H95.1097Z" fill="white" />
    <path d="M62.0844 22.1551V23.6712C61.3807 22.5959 60.2548 21.926 58.7595 21.926C56.3141 21.926 54.3086 23.9533 54.3086 26.5624C54.3086 29.1715 56.3141 31.1988 58.7595 31.1988C60.2548 31.1988 61.3807 30.5289 62.0844 29.4536V30.9697H63.615V22.1551H62.0844ZM58.953 29.718C57.1938 29.718 55.8391 28.3606 55.8391 26.5624C55.8391 24.7642 57.1938 23.4068 58.953 23.4068C60.7298 23.4068 62.0844 24.7642 62.0844 26.5624C62.0844 28.3606 60.7298 29.718 58.953 29.718Z" fill="white" />
    <path d="M70.3144 21.926C69.0829 21.926 68.1153 22.3843 67.4644 23.4244V22.1551H65.9339V30.9697H67.4644V26.298C67.4644 24.2177 68.6079 23.3892 70.0153 23.3892C71.3699 23.3892 72.1968 24.2001 72.1968 25.6457V30.9697H73.7273V25.5575C73.7273 23.301 72.3727 21.926 70.3144 21.926Z" fill="white" />
    <path d="M83.1472 18.6293V23.6712C82.4435 22.5959 81.3176 21.926 79.8223 21.926C77.3769 21.926 75.3714 23.9533 75.3714 26.5624C75.3714 29.1715 77.3769 31.1988 79.8223 31.1988C81.3176 31.1988 82.4435 30.5289 83.1472 29.4536V30.9697H84.6778V18.6293H83.1472ZM80.0158 29.718C78.2565 29.718 76.9019 28.3606 76.9019 26.5624C76.9019 24.7642 78.2565 23.4068 80.0158 23.4068C81.7926 23.4068 83.1472 24.7642 83.1472 26.5624C83.1472 28.3606 81.7926 29.718 80.0158 29.718Z" fill="white" />
    <path d="M88.5272 23.636V22.1551H86.9967V30.9697H88.5272V26.298C88.5272 24.2177 89.9522 23.5478 91.2716 23.6007V21.9965C90.1633 21.9965 89.0902 22.4019 88.5272 23.636Z" fill="white" />
    <path d="M96.4224 31.1988C98.9909 31.1988 101.067 29.1715 101.067 26.5624C101.067 23.9533 98.9909 21.926 96.4224 21.926C93.8539 21.926 91.7956 23.9533 91.7956 26.5624C91.7956 29.1715 93.8539 31.1988 96.4224 31.1988ZM96.4224 29.7004C94.6807 29.7004 93.3261 28.3429 93.3261 26.5624C93.3261 24.7819 94.6807 23.4244 96.4224 23.4244C98.1816 23.4244 99.5362 24.7819 99.5362 26.5624C99.5362 28.3429 98.1816 29.7004 96.4224 29.7004Z" fill="white" />
    <path d="M103.645 20.5509C104.208 20.5509 104.666 20.0925 104.666 19.5284C104.666 18.9643 104.208 18.4883 103.645 18.4883C103.065 18.4883 102.607 18.9643 102.607 19.5284C102.607 20.0925 103.065 20.5509 103.645 20.5509ZM102.871 30.9697H104.402V22.1551H102.871V30.9697Z" fill="white" />
    <path d="M114.02 18.6293V23.6712C113.316 22.5959 112.19 21.926 110.695 21.926C108.25 21.926 106.244 23.9533 106.244 26.5624C106.244 29.1715 108.25 31.1988 110.695 31.1988C112.19 31.1988 113.316 30.5289 114.02 29.4536V30.9697H115.55V18.6293H114.02ZM110.888 29.718C109.129 29.718 107.775 28.3606 107.775 26.5624C107.775 24.7642 109.129 23.4068 110.888 23.4068C112.665 23.4068 114.02 24.7642 114.02 26.5624C114.02 28.3606 112.665 29.718 110.888 29.718Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M48.5316 30.0435H8.08887C9.55939 20.2344 18.0527 12.7109 28.3102 12.7109C38.5677 12.7109 47.0611 20.2344 48.5316 30.0435Z" fill="white" />
    <path d="M39.2871 24.2661C39.2871 25.5424 38.2524 26.5771 36.9761 26.5771C35.6997 26.5771 34.665 25.5424 34.665 24.2661C34.665 22.9898 35.6997 21.9551 36.9761 21.9551C38.2524 21.9551 39.2871 22.9898 39.2871 24.2661Z" fill="black" />
    <path d="M20.7998 24.2661C20.7998 25.5424 19.7651 26.5771 18.4887 26.5771C17.2124 26.5771 16.1777 25.5424 16.1777 24.2661C16.1777 22.9898 17.2124 21.9551 18.4887 21.9551C19.7651 21.9551 20.7998 22.9898 20.7998 24.2661Z" fill="black" />
    <path d="M35.5329 18.7177C35.3227 18.5964 35.2507 18.3277 35.372 18.1175L40.6447 8.98494C40.7661 8.77479 41.0348 8.70278 41.245 8.82412C41.4551 8.94545 41.5271 9.21418 41.4058 9.42433L36.1331 18.5569C36.0118 18.7671 35.743 18.8391 35.5329 18.7177Z" fill="white" />
    <path d="M21.0326 18.7177C21.2427 18.5964 21.3147 18.3277 21.1934 18.1175L15.9207 8.98494C15.7994 8.77479 15.5306 8.70278 15.3205 8.82412C15.1103 8.94545 15.0383 9.21418 15.1596 9.42433L20.4323 18.5569C20.5537 18.7671 20.8224 18.8391 21.0326 18.7177Z" fill="white" />
  </svg>

};
