import { Button, Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './MoreDetailsModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Product } from '@shared/types/common';
import { Quest } from '@/shared/types/common';
import defaultProduct from "@assets/images/defaultProduct.png";
import loader from "@assets/images/icons/loader.svg";
import { useBuyProduct } from '@/hoc/useBuyProduct';
import { NavLink } from 'react-router-dom';

export enum ProductType {
  QUEST = 'quest',
  PRODUCT = 'product',
}

export interface MoreDetailsModalData {
  product: Product & Quest;
  isAuthenticated?: boolean;
  onLoginClick?: () => void;
  buttonText?: string;
  closeable?: boolean;
  productType?: ProductType;
  price?: string;
}

export const MoreDetailsModal = () => {
  const { isModalOpen, closeModal, getModalData, openModal } = useModals();
  const { t } = useTranslation(["main", "help", "welcome"]);

  const modalData = getModalData<MoreDetailsModalData>(ModalName.MORE_DETAILS)

  const { buyProduct, loading } = useBuyProduct();

  if (!modalData || !modalData.product) {
    return null;
  }

  const { product, isAuthenticated, buttonText, closeable, productType, price } = modalData

  const handleLoginClick = () => {
    openModal(ModalName.LOGIN);
  }

  const handleBuyClick = () => {
    buyProduct(product.id, product.item_type);
  }

  return (
    <Modal isOpen={isModalOpen(ModalName.MORE_DETAILS)} onClose={closeModal}>

      <div className={styles.title}>
        <div className={styles.titleText}>
          Лутбокс {product.name}
        </div>
      </div>

      <div style={{ padding: "16px 16px" }}>
        <img
          src={product.image || defaultProduct}
          alt={product.name}
          className={styles.modalImage}
        />
        <div className={styles.modal_inner}>
          <div style={{ display: "flex", gap: "12px" }}>
            <div className={styles.modal_inner_price}>

              <div className={styles.modal_inner_price_p}>
                {price} PM баллов
              </div>
            </div>
            {product.game && (
              <div className={styles.modal_inner_price}>
                <div className={styles.modal_inner_price_p}>
                  {product.game}
                </div>
              </div>
            )}
          </div>
          <div className={styles.modal_inner_desc}>
            <p className={styles.modal_inner_desc_d} dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
        </div>
        <div className={styles.modal_inner_buttons}>
          <div className={styles.modal_inner_buttons_take}>
            {isAuthenticated ? (
              productType === ProductType.PRODUCT ?
                <button
                  className={styles.modal_inner_buttons_take_t}
                  onClick={handleBuyClick}
                  disabled={loading}
                >
                  {loading ? (
                    <img
                      className={styles.loader}
                      src={loader}
                      alt="Загрузка..."
                    />
                  ) : buttonText
                  }
                </button> :
                <button className={styles.modal_inner_buttons_take_t}>
                  <NavLink to={`${product.url ? product.url : 'https://parimatch.kz/ru/gamification/daily-quests'}`}
                    target='_blank'
                    className={styles.btn}>
                    Начать
                  </NavLink>
                </button>
            ) : (
              <button
                className={styles.modal_inner_buttons_take_t}
                onClick={handleLoginClick}
              >
                {buttonText}
              </button>
            )}
          </div>

          {closeable && (
            <Button
              onClick={closeModal}
              variant="dark"
              title='Закрыть'
            />
          )}
        </div>
      </div>
    </Modal>
  );
};