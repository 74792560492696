import { Button, ModalName } from "@/shared/components";
import styles from "./SuperprizeTab.module.scss";
import superprize from "@assets/images/cybermarathon/superprize.png";
import superprize_mob from "@assets/images/cybermarathon/superprize-mob.png";
import { useEffect, useState } from "react";
import { useModals } from "@/shared/contexts/modal-context";
import another from "@assets/images/cybermarathon/another.png";
import laptop from "@assets/images/cybermarathon/laptop.png";
import ps from "@assets/images/cybermarathon/ps5.png";
import { useTranslation } from "react-i18next";
export const SuperprizeTab = () => {
  const { t } = useTranslation(['cybermarathon', 'main']);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 800);
  }, []);

  const { openModal } = useModals();

  const handleMoreDetailsClick = () => {
    openModal(ModalName.ITEM_DETAILS, {
      title: t('superprize_title_2'),
      subtitle: t('superprize_description_2'),
      text: [
        t('superprize_proccessor'),
        t('superprize_cooler'),
        t('superprize_motherboard'),
        t('superprize_ram'),
        t('superprize_video_card'),
        t('superprize_drive'),
        t('superprize_power_supply'),
        t('superprize_case')
      ],
      image: superprize
    });
  };

  return (
    <div>
      <div className={styles.wrapper} style={{ backgroundImage: `url(${isMobile ? superprize_mob : superprize})` }}>
        <div className={styles.title}>
          {t('superprize_title')}
        </div>
        <div className={styles.description}>
          {t('superprize_description')}
        </div>
        <Button title={t('main:readMoreBtn')} variant="light" onClick={handleMoreDetailsClick} />
      </div>

      <div className={styles.another}>
        <div className={styles.anotherTitle}>
          {t('another_title')}
        </div>
        <div className={styles.anotherSubtitle}>
          {t('another_description')}
        </div>
        <div className={styles.anotherItems}>

          <div className={styles.anotherItem} style={{ backgroundImage: `url(${another})` }}>
            <div className={styles.anotherItemInfo}>
              <div className={styles.anotherItemTitle}>
                {t('another_item_title')}
              </div>
              <div className={styles.anotherItemDescription}>
                {t('another_item_description')}
              </div>
            </div>
            <div>
              <img src={laptop} alt="laptop" />
            </div>
          </div>

          <div className={styles.anotherItem} style={{ backgroundImage: `url(${another})` }}>
            <div className={styles.anotherItemInfo}>
              <div className={styles.anotherItemTitle}>
                {t('another_item_title_2')}
              </div>
              <div className={styles.anotherItemDescription}>
                {t('another_item_description_2')}
              </div>
            </div>
            <div className={styles.anotherItemImage}>
              <img src={ps} alt="ps" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};