import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import styles from "../QuestGuide/QuestGuide.module.scss";
import { useTranslation } from "react-i18next";
import pic_one from "../../../assets/images/quest_guide_one.png";
import pic_two from "../../../assets/images/quest_guide_two.png";
import pic from "../../../assets/images/quest_guide_pic.png";

const QuestGuide = ({ onClickPrevious, onClickNext }) => {
  const { t } = useTranslation(["main", "welcome"]);

  return (
    <div className={`${styles.container}`}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_group}>
          <div className={styles.wrapper_group_one}>
            <div className={styles.title}>
              {t("welcome:quest_guide")}{" "}
              <span className={styles.yellow}>
                {t("welcome:quest_guide_hg")}
              </span>
            </div>
            <div className={styles.info}>
              <div className={styles.group_one}>
                <img src={pic_one} alt="Quest Guide One" />
                <div className={styles.obj_one}>
                  <h1 className={styles.title}>{t("welcome:obj_one_title")}</h1>
                  <p className={styles.desc}>{t("welcome:obj_one_desc")}</p>
                </div>
              </div>
              <div className={styles.group_two}>
                <img src={pic_two} alt="Quest Guide Two" />
                <div className={styles.obj_two}>
                  <h1 className={styles.title}>{t("welcome:obj_two_title")}</h1>
                  <p className={styles.desc}>{t("welcome:obj_two_desc")}</p>
                </div>
                <div className={styles.obj_two}>
                  <h1 className={styles.title}>{t("welcome:steps")}</h1>
                  <p className={styles.desc}>
                  {t("welcome:steps_one")}
                  </p>
                  <p className={styles.desc}>
                  {t("welcome:steps_two")}
                  </p>
                  <p className={styles.desc}>
                  {t("welcome:steps_three")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.button_group}>
              <div onClick={onClickPrevious}>
                <button className={styles.btn_back}>{t("back")}</button>
              </div>
              <div onClick={onClickNext}>
                <button className={styles.btn}>{t("next")}</button>
              </div>
            </div>
          </div>
          <div className={styles.wrapper_group_two}>
            <img src={pic} className={styles.intro_pic} alt="intro" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestGuide;
