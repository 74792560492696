import { Modal, Button } from '@shared/components';
import { useModals } from '@shared/contexts/modal-context';
import { ModalName } from '../types';
import styles from './RegisterModal.module.scss';
import { LoginInput } from '../login-modal/components/LoginInput';
import { useRef, useState, useCallback } from 'react';
import { LoginFormData, LoginFormErrors } from '../login-modal/types';
import { PasswordInput } from '../login-modal/components/PasswordInput';
import { useTranslation } from 'react-i18next';
import { CheckSquareIcon } from '@/shared/icons/CheckSquareIcon';
import axios from 'axios';
import { loginSuccess } from '@/store/slices/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const CheckboxOption = ({
  label,
  linkText,
  onLinkClick,
  isChecked,
  onToggle
}: {
  label: string;
  linkText: string;
  onLinkClick: () => void;
  isChecked: boolean;
  onToggle: () => void
}) => (
  <div className={styles.offer}>
    <div>
      {label} <span onClick={onLinkClick}>{linkText}</span>
    </div>
    <div className={styles.offerIcon} onClick={onToggle}>
      {isChecked && <CheckSquareIcon />}
    </div>
  </div>
);

export const RegisterModal = () => {
  const { t } = useTranslation(['main', 'reg', 'errors']);
  const { isModalOpen, closeModal, openModal } = useModals();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<LoginFormData>({
    login: '',
    password: '',
    loginType: 'phone',
  });

  const [isLoginValid, setLoginValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [isPasswordHasNum, setPasswordHasNum] = useState(true);
  const [isPasswordMin, setPasswordMin] = useState(true);
  const [isPhoneValid, setPhoneIsValid] = useState(true);

  const [focusedInput, setFocusedInput] = useState<'phone' | 'email' | 'account' | 'password' | null>(null);
  const [errors, setErrors] = useState<LoginFormErrors>({});
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isOfferChecked, setIsOfferChecked] = useState(false);
  const [isBonusChecked, setIsBonusChecked] = useState(false);

  const phoneRef = useRef(null);
  const inputRef = useRef(null);

  const isFormValid = isLoginValid && isPasswordValid && isPasswordHasNum && isPasswordMin && isOfferChecked;

  const handleLoginChange = useCallback((value: string) => {
    setFormData(prev => ({ ...prev, login: value }));

    setPhoneIsValid(true);
    setShowError(false);

    if (formData.loginType === 'phone') {
      setLoginValid(value.length === 11);
    } else {
      setLoginValid(Boolean(value.length));
    }
  }, [formData.loginType]);

  const handlePasswordChange = useCallback((value: string) => {
    setFormData(prev => ({ ...prev, password: value }));

    const hasNum = /\d/.test(value);
    const isMinLength = /^.{6,}$/.test(value);

    setPasswordHasNum(hasNum);
    setPasswordMin(isMinLength);
    setPasswordValid(hasNum && isMinLength);
  }, []);

  const register = useCallback(async (login: string, password: string, isOfferChecked: boolean) => {
    try {
      const response1 = await axios.post(`${process.env.REACT_APP_GR8_URL}/registration/byform`, {
        formName: 'LANDING_REGISTRATION',
        phone: login,
        password: password,
        defaultCurrency: 'KZT',
        selectedLanguage: 'ru',
        isPlayerAgree: isOfferChecked,
        nnBonus: isBonusChecked ? '3' : '0'
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json'
        }
      });

      if (response1.data) {
        try {
          const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/login_by_site`, {
            pm_id: response1.data.accountInfo.number,
            phone: response1.data.accountInfo.phone
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });

          if (response2.data.status === 'success') {
            dispatch(loginSuccess({
              isAuthenticated: true,
              user: response2.data.userInfo
            }));

            localStorage.setItem('auth_token', response2.data.meta.access_token)

            navigate('/welcome');
            closeModal();
          }
        } catch (error) {
          console.error('Login after registration error:', error);
          setErrors(prev => ({ ...prev, general: t('errors:login_failed') }));
        }
      }
    } catch (error: any) {
      console.error('Registration error:', error);
      handleRegistrationError(error);
    }
  }, [isBonusChecked, dispatch, navigate, closeModal, t]);

  const handleRegistrationError = useCallback((error: any) => {
    if (error.response) {
      if (error.response.data.fieldErrors?.phone) {
        if (error.response.data.fieldErrors.phone[0].type === 'AllowedChars') {
          setErrorText(t('errors:not_supported'));
          setPhoneIsValid(false);
          setShowError(true);
        }
      } else if (error.response.data.modelErrors) {
        if (error.response.data.modelErrors[0].type === 'PhoneCurrencyUnique') {
          setErrorText(t('errors:number_registered'));
          setPhoneIsValid(false);
          setShowError(true);
        }
      }
    } else {
      setErrorText(t('errors:error_data'));
      setShowError(true);
    }
  }, [t]);

  const handleSubmit = useCallback(async () => {
    if (!isFormValid) return;

    setIsLoading(true);
    try {
      await register(formData.login, formData.password, isOfferChecked);
    } catch (error) {
      console.error('Submit error:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isFormValid, register, formData, isOfferChecked]);

  const handleOfferLinkClick = useCallback(() => {
    openModal(ModalName.OFFER);
  }, [openModal]);

  const handleBonusLinkClick = useCallback(() => {
    openModal(ModalName.BONUS);
  }, [openModal]);

  return (
    <Modal isOpen={isModalOpen(ModalName.REGISTER)} onClose={closeModal}>
      <div className={styles.title}>
        {t('register')}
      </div>

      <div className={styles.wrapper}>
        {errors.general && (
          <div className={styles.errorMessage}>
            {errors.general}
          </div>
        )}

        {showError && (
          <div className={styles.errorMessage}>
            {errorText}
          </div>
        )}

        <LoginInput
          type={formData.loginType}
          value={formData.login}
          onChange={handleLoginChange}
          onFocus={() => setFocusedInput(formData.loginType)}
          onBlur={() => setFocusedInput(null)}
          isValid={isLoginValid && isPhoneValid}
          ref={phoneRef}
          inputRef={inputRef}
          isFocused={focusedInput === formData.loginType}
        />

        <PasswordInput
          value={formData.password}
          onChange={handlePasswordChange}
          onFocus={() => setFocusedInput('password')}
          onBlur={() => setFocusedInput(null)}
          isValid={isPasswordValid}
          isFocused={focusedInput === 'password'}
        />

        <div className={styles.condition}>
          <div className={`${styles.conditionItem} ${isPasswordMin ? '' : styles.errorTxt}`}>
            {t('errors:min_6_symbols')}
          </div>
          <div className={`${styles.conditionItem} ${isPasswordHasNum ? '' : styles.errorTxt}`}>
            {t('errors:min_1_number')}
          </div>
        </div>

        <div className={styles.row}>
          <CheckboxOption
            label={t('reg:more_than_21')}
            linkText={t('reg:accept_offer')}
            onLinkClick={handleOfferLinkClick}
            isChecked={isOfferChecked}
            onToggle={() => setIsOfferChecked(!isOfferChecked)}
          />
        </div>

        <div className={styles.row}>
          <CheckboxOption
            label='Активировать бонус на первый депозит '
            linkText='Условия бонусного предложения'
            onLinkClick={handleBonusLinkClick}
            isChecked={isBonusChecked}
            onToggle={() => setIsBonusChecked(!isBonusChecked)}
          />
        </div>

        <div className={styles.row}>
          <Button
            wide
            title='Регистрация'
            disabled={isLoading || !isFormValid}
            onClick={handleSubmit}
          />
        </div>

        <div className={styles.row}>
          Есть аккаунт? &nbsp;
          <span onClick={() => openModal(ModalName.LOGIN)}>
            Войти
          </span>
        </div>

        <div className={styles.rulesWrapper}>
          Выполняя регистрацию или вход, вы соглашаетесь с
          <div className={styles.rules} onClick={() => openModal(ModalName.OFFER_RULES)}>Правилами и Условиями Aкции</div>
        </div>
      </div>
    </Modal>
  );
}; 