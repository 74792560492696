import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import styles from "./ShopQuests.module.scss";
import { useTranslation } from "react-i18next";
import pic from "../../../assets/images/shop_quest_pic.png";

const ShopQuests = ({ onClickPrevious, onClickNext }) => {
  const { t } = useTranslation(["main", "welcome", "rockets_welcome"]);

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  return (
    <div className={`${styles.container}`}>

      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            { isRockets ? t("rockets_welcome:how_this_works") : t("welcome:shop_and_quests_shop")}{" "}
            { !isRockets && <span className={styles.yellow}>
              {t("welcome:shop_and_quests_quests")}
            </span>}
          </div>
          { isRockets ? 
          <>
            <div className={styles.rockets_subtitle}>
              {t("rockets_welcome:case1")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer1")}
            </div>
            <div className={styles.rockets_subtitle}>
              {t("rockets_welcome:case2")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer2")}
            </div>
            <div className={styles.rockets_subtitle}>
              {t("rockets_welcome:case3")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer3")}
            </div>
            <div className={styles.rockets_subtitle}>
              {t("rockets_welcome:case4")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer4")}
            </div>
            <div className={styles.rockets_subtitle}>
              {t("rockets_welcome:case5")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer5_1")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer5_2")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer5_3")}
            </div>
            <div className={styles.rockets_txt}>
              {t("rockets_welcome:answer5_4")}
            </div>
          </>  
          : <>
          <div className={styles.info}>
            <div>{t("welcome:shop_and_quests_text1")}</div>
            <div className={styles.txt}>
              {t("welcome:shop_and_quests_text2")}
            </div>
            <div className={styles.txt}>
              {t("welcome:shop_and_quests_text3")}
            </div>
          </div>
          
          </>}
          <div className={styles.button_group}>
            <div onClick={onClickPrevious}>
              <button className={styles.btn_back}>{t("back")}</button>
            </div>
            <div onClick={onClickNext}>
              <button className={styles.btn}>{t("main:next")}</button>
            </div>
          </div>
        </div>

        <img src={pic} className={styles.pic} alt="Shop Quest" />
      </div>
    </div>
  );
};

export default ShopQuests;
