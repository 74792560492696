import * as React from 'react';

export const useIsInIframe = () => {
	const [isInIframe, setIsInIframe] = React.useState(false);

	React.useEffect(() => {
		setIsInIframe(window.self !== window.top);
	}, []);

	return isInIframe;
};
