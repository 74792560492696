export enum ModalName {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgotPassword',
  SUCCESS = 'success',
  PROFILE = 'profile',
  HELP = 'help',
  OFFER_RULES = 'offerRules',
  OFFER = 'offer',
  BONUS = 'bonus',
  TRADE_LINK_HELP = 'tradeLinkHelp',
  CONFIRM = 'confirm',
  MORE_DETAILS = 'moreDetails',
  ITEM_DETAILS = 'itemDetails',
  // Add more modal types here
}

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export interface BaseModalProps {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} 