import styles from "./Cybermarathon.module.scss";
import cybermarathon from "@assets/images/cybermarathon/cybermarathon.webp";
import img1 from "@assets/images/cybermarathon/img1.png";
import img2 from "@assets/images/cybermarathon/img2.png";
import img3 from "@assets/images/cybermarathon/img3.png";
import background from "@assets/images/cybermarathon/background.png";
import { useEffect, useState, useRef, useCallback } from "react";
import { RulesTab, SuperprizeTab } from "./components";
import { TournamentsTab } from "./components";
import { API_KEY, SHEET_ID, SPREADSHEET_ID } from "@/config/google-sheets";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";

interface Tournament {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  logo?: string;
  status?: string;
}

enum Tabs {
  TOURNAMENTS = "tournaments",
  SUPERPRIZES = "superprizes",
  RULES = "rules"
}

export const Cybermarathon = () => {
  const { t } = useTranslation('cybermarathon');
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.TOURNAMENTS);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [updatedTournaments, setUpdatedTournaments] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState(false);
  const [quests, setQuests] = useState<any[]>([]);
  
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const previousQuestsRef = useRef<string>("");

  const processTournamentData = (data: any): Tournament[] => {
    if (!data.values || data.values.length < 3) {
      return [];
    }

    const names = data.values[0];
    const startDates = data.values[1];
    const endDates = data.values[2];
    const logos = data.values[3];
    const id = data.values[4];

    const tournamentData: Tournament[] = [];

    for (let i = 1; i < names.length; i++) {
      tournamentData.push({
        id: parseInt(id[i], 10),
        name: names[i],
        startDate: startDates[i],
        endDate: endDates[i],
        logo: logos[i]
      });
    }

    return tournamentData;
  };

  const updateTournamentsWithQuestStatus = useCallback((baseTournaments: Tournament[], userQuests: any[]) => {
    return baseTournaments.map(tournament => {
      const matchingQuest = userQuests.find(quest => parseInt(quest.id, 10) === tournament.id);
      
      if (matchingQuest) {
        return {
          ...tournament,
          status: matchingQuest.status || 'unknown'
        };
      }
      
      return tournament;
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_ID}?alt=json&key=${API_KEY}`;

      try {
        const response = await fetch(url);
        const result = await response.json();

        if (!result.values || result.values.length === 0) {
          console.error("Ошибка: Пустые данные из Google Sheets");
          return;
        }

        const parsedTournaments = processTournamentData(result);
        setTournaments(parsedTournaments);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchQuests();
    }
  }, [isAuthenticated]);

  const fetchQuests = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('auth_token')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/my_quests`,
        {
          params: {
            cacheBuster: Math.random(),
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );
      if (response.data && response.data.data) {
        setQuests(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching quests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tournaments.length > 0) {
      const currentQuestsString = JSON.stringify(quests);
      
      if (currentQuestsString !== previousQuestsRef.current) {
        const updatedTournaments = updateTournamentsWithQuestStatus(tournaments, quests);
        setUpdatedTournaments(updatedTournaments);
        
        previousQuestsRef.current = currentQuestsString;
      }
    }

  }, [quests, isAuthenticated, tournaments, updateTournamentsWithQuestStatus]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.left} dangerouslySetInnerHTML={{ __html: t('main_title') }} />
        <div className={styles.right}>
          <img src={cybermarathon} alt="" />
        </div>
      </div>

      <Swiper
        spaceBetween={20}
        navigation
        className={styles.swiper}
        breakpoints={{
          1100: {
            slidesPerView: 3
          },
          600: {
            slidesPerView: 2.3
          },
          300: {
            slidesPerView: 1.1
          },
        }}
      >
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.missionItem} style={{ backgroundImage: `url(${background})` }}>
            <div className={styles.missionItemInfo}>
              <div className={styles.missionItemTitle}>
                {t('mission_first_title')}
              </div>
              <div className={styles.missionItemDescription}>
                {t('mission_first_description')}
              </div>
            </div>
            <div className={styles.missionItemImage}>
              <img src={img1} alt="" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.missionItem} style={{ backgroundImage: `url(${background})` }}>
            <div className={styles.missionItemInfo}>
              <div className={styles.missionItemTitle}>
                {t('mission_second_title')}
              </div>
              <div className={styles.missionItemDescription}>
                {t('mission_second_description')}
              </div>
            </div>
            <div className={styles.missionItemImage}>
              <img src={img2} alt="" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.swiperSlide}>
          <div className={styles.missionItem} style={{ backgroundImage: `url(${background})` }}>
            <div className={styles.missionItemInfo}>
              <div className={styles.missionItemTitle}>
                {t('mission_third_title')}
              </div>
              <div className={styles.missionItemDescription}>
                {t('mission_third_description')}
              </div>
            </div>
            <div className={styles.missionItemImage}>
              <img src={img3} alt="" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className={styles.tabs}>
        <div className={`${styles.tab} ${activeTab === Tabs.TOURNAMENTS ? styles.active : ""}`} onClick={() => setActiveTab(Tabs.TOURNAMENTS)}>
          {t('tournaments_title')}
        </div>
        <div className={`${styles.tab} ${activeTab === Tabs.SUPERPRIZES ? styles.active : ""}`} onClick={() => setActiveTab(Tabs.SUPERPRIZES)}>
          {t('superprizes_title')}
        </div>
        <div className={`${styles.tab} ${activeTab === Tabs.RULES ? styles.active : ""}`} onClick={() => setActiveTab(Tabs.RULES)}>
          {t('rules_title')}
        </div>
      </div>

      {activeTab === Tabs.TOURNAMENTS && <TournamentsTab tournaments={updatedTournaments} />}
      {activeTab === Tabs.SUPERPRIZES && <SuperprizeTab />}
      {activeTab === Tabs.RULES && <RulesTab />}
    </div>
  );
};