import styles from './Profile.module.scss'
import SteamLogin from '../../components/SteamLogin/SteamLogin'
import TradeLink from '../../components/TradeLink/TradeLink'
import { useSelector } from 'react-redux'
import MatchHistory from '../../components/MatchHistory/MatchHistory'
import ActivityHistory from '../../components/ActivityHistory/ActivityHistory'
import { RootState } from '@/store'
import { Account } from './components'

export const Profile = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} container-main`}>
        <div className={styles.row}>
          {!isRockets && <Account />}

          {
            !isRockets &&
            <TradeLink />
          }
        </div>
        {
          !isRockets &&
          <div className={styles.row}>
            {
              user?.steam_id ?
                <MatchHistory /> :
                <SteamLogin />
            }
          </div>
        }
        <ActivityHistory />
      </div>
    </div>
  )
}
