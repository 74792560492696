import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Hotjar from '@hotjar/browser';
import { AuthState, LoginPayload } from './types';

const initialState: AuthState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  user: null,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<LoginPayload>) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      localStorage.setItem('isAuthenticated', 'true');

      if (state.user && process.env.REACT_APP_ENV === 'production') {
        Hotjar.identify(state.user.pm_id, {
          phone: state.user.phone || '',
        });
      }
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('auth_token');
    },
    updateAuth: (state, action: PayloadAction<LoginPayload>) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;

      if (state.user && process.env.REACT_APP_ENV === 'production') {
        Hotjar.identify(state.user.pm_id, {
          phone: state.user.phone || '',
        });
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { loginSuccess, logoutSuccess, updateAuth, setLoading } = authSlice.actions;
export default authSlice.reducer; 