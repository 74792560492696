import { useSelector } from "react-redux";
import styles from "./Product.module.scss";
import defaultProduct from "../../assets/images/defaultProduct.png";
import loader from "../../assets/images/icons/loader.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useModals } from "@/shared/contexts/modal-context";
import { ModalName } from "@/shared/components/modals";
import { ProductType } from "@/shared/components/modals/more-details-modal/MoreDetailsModal";
import { useBuyProduct } from "@/hoc/useBuyProduct";
import { CoinIcon, PmPointIcon } from "@/shared/icons";

const Product = ({ quest }) => {
  const { t } = useTranslation(["main", "notifications"]);
  const [isExpanded, setIsExpanded] = useState(false);
  const wordCount = quest.description.split(/\s+/).filter((word) => word).length;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { buyProduct, loading } = useBuyProduct();

  const modals = useModals();

  const handleLoginClick = () => {
    modals.openModal(ModalName.LOGIN);
  };

  const onBuyClick = () => {
    buyProduct(quest.id, quest.item_type);
  };

  const handleMoreDetailsClick = () => {
    modals.openModal(ModalName.MORE_DETAILS, {
      product: quest,
      showLoader: loading,
      isAuthenticated: isAuthenticated,
      buttonText: t("take"),
      productType: ProductType.PRODUCT,
      closeable: true,
      price: quest.price
    });
  };

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  return (
    <div className={styles.container}>
      <div
        className={styles.imgWrapper}
        style={{
          backgroundImage: `url(${quest.image ? quest.image : defaultProduct})`,
        }}
      />
      <div className={styles.chestTitleWrapper}>
        <div className={styles.chestTitle}>
          <div className={styles.chestName}>
            <div className={styles.chestName_left}>
              <div>{quest.price} </div>
              <div>
                {
                  isRockets ?
                  <CoinIcon /> :
                  <PmPointIcon />
                }
              </div>
            </div>
            {quest.game && (
              <div className={styles.chestName_right}>
                <div>{quest.game}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.productName}>{quest.name}</div>

      <div className={styles.questTaskWrapper}>
        <div
          className={styles.questTask}
          style={{
            maxHeight: isExpanded ? "none" : "45px",
            overflow: isExpanded ? "visible" : "hidden",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: quest.description }} />
        </div>
      </div>

      <div className={styles.productButtons}>
        {wordCount > 6 && (
          <button
            className={styles.readMoreBtn}
            onClick={handleMoreDetailsClick}
          >
            {t("readMoreBtn")}
          </button>
        )}
        {isAuthenticated ? (
          <div className={styles.btn} onClick={onBuyClick}>
            <button disabled={loading}>
              {loading ? (
                <img className={styles.loader} src={loader} alt="" />
              ) : (
                t("take")
              )}
            </button>
          </div>
        ) : (
          <div onClick={handleLoginClick} className={styles.btn}>
            <button>{t("take")}</button>
          </div>
        )}
      </div>
    </div >
  );
};

export default Product;
