type ChevronIconProps = {
  rotate?: '0' | '90' | '180' | '270';
};

export const ChevronIcon = ({ rotate = '0' }: ChevronIconProps) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${rotate}deg)` }}>
      <path d="M8.3 13.2C7.9 12.8 7.9 12.2 8.3 11.8C9.4 10.7 11.7 8.4 12.8 7.3C13.2 6.9 13.8 6.9 14.2 7.3C14.6 7.7 14.6 8.3 14.2 8.7C13.3 9.6 10.4 12.5 10.4 12.5C10.4 12.5 13.3 15.3 14.2 16.3C14.6 16.7 14.6 17.3 14.2 17.7C13.8 18.1 13.2 18.1 12.8 17.7C11.7 16.6 9.4 14.3 8.3 13.2Z" fill="#BBB5AA" />
    </svg>
  )
};
