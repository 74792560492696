import React from 'react';
import styles from './Button.module.scss'

interface ButtonProps {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  size?: 'large' | 'medium' | 'small';
  variant?: 'primary' | 'dark' | 'light' | 'disabled';
  wide?: boolean;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  title,
  onClick,
  disabled = false,
  className,
  size = 'medium',
  variant = 'primary',
  wide = false,
  children,
}) => {
  const colorClass = variant ? styles[variant] : styles.primary;
  const sizeClass = size ? styles[size] : styles.medium;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <button 
      className={`${styles.btn} ${sizeClass} ${colorClass} ${wide && styles.wide} ${className || ''} ${disabled && styles.disabled}`}
      onClick={handleClick}
      disabled={disabled}
      type="button"
      aria-disabled={disabled}
    >
      {title}
      {children}
    </button>
  )
}
