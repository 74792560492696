import { Button, Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './ItemDetailsModal.module.scss';
import { useTranslation } from 'react-i18next';

export interface ItemDetailsModalData {
  title?: string;
  subtitle?: string;
  text?: string[]
  image?: string
}

export const ItemDetailsModal = () => {
  const { isModalOpen, closeModal, getModalData } = useModals();
  const { t } = useTranslation(["main", "help", "welcome"]);

  const itemDetailsData = getModalData<ItemDetailsModalData>(ModalName.ITEM_DETAILS) || {};

  const {
    title = t("confirm_action"),
    subtitle,
    text,
    image
  } = itemDetailsData;

  if (!text) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen(ModalName.ITEM_DETAILS)} onClose={closeModal}>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.title}>
        {title}
      </div>

      <div className={styles.subtitle}>
        {subtitle}
      </div>

      <div className={styles.text}>
        {
          text.map((item, index) => (
            <div className={styles.textItem} key={index}>
              {item}
            </div>
          ))
        }
      </div>

      <div className={styles.button}>
        <Button title={t("main:close")} variant="light" onClick={closeModal} />
      </div>
    </Modal>
  );
};