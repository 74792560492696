export const LogoutIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12C0 5.37258 5.37258 0 12 0H30C36.6274 0 42 5.37258 42 12V30C42 36.6274 36.6274 42 30 42H12C5.37258 42 0 36.6274 0 30V12Z" fill="#3E3C37" />
      <mask id="mask0_7142_16039" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="9" y="9" width="24" height="24">
        <rect x="9" y="9" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7142_16039)">
        <path d="M14 30C13.45 30 12.9792 29.8042 12.5875 29.4125C12.1958 29.0208 12 28.55 12 28V14C12 13.45 12.1958 12.9792 12.5875 12.5875C12.9792 12.1958 13.45 12 14 12H20C20.2833 12 20.5208 12.0958 20.7125 12.2875C20.9042 12.4792 21 12.7167 21 13C21 13.2833 20.9042 13.5208 20.7125 13.7125C20.5208 13.9042 20.2833 14 20 14H14V28H20C20.2833 28 20.5208 28.0958 20.7125 28.2875C20.9042 28.4792 21 28.7167 21 29C21 29.2833 20.9042 29.5208 20.7125 29.7125C20.5208 29.9042 20.2833 30 20 30H14ZM26.175 22H19C18.7167 22 18.4792 21.9042 18.2875 21.7125C18.0958 21.5208 18 21.2833 18 21C18 20.7167 18.0958 20.4792 18.2875 20.2875C18.4792 20.0958 18.7167 20 19 20H26.175L24.3 18.125C24.1167 17.9417 24.025 17.7167 24.025 17.45C24.025 17.1833 24.1167 16.95 24.3 16.75C24.4833 16.55 24.7167 16.4458 25 16.4375C25.2833 16.4292 25.525 16.525 25.725 16.725L29.3 20.3C29.5 20.5 29.6 20.7333 29.6 21C29.6 21.2667 29.5 21.5 29.3 21.7L25.725 25.275C25.525 25.475 25.2875 25.5708 25.0125 25.5625C24.7375 25.5542 24.5 25.45 24.3 25.25C24.1167 25.05 24.0292 24.8125 24.0375 24.5375C24.0458 24.2625 24.1417 24.0333 24.325 23.85L26.175 22Z" fill="white" />
      </g>
    </svg>
  )
}