import Calendar from "./Calendar/Calendar";

export const Tournaments = () => {

  return (
    <div>
      <Calendar />
    </div>
  )
}
