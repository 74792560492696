import { TgIcon, TiktokIcon, YoutubeIcon } from "@/shared/icons";
import { NavLink } from "react-router-dom";
import styles from "./SocialLinks.module.scss";

export const SocialLinks = () => {
  return (
    <div className={styles.social}>
      <div className={styles.socialItem}>
        <NavLink to="https://t.me/pmkaz" target="_blank">
          <TgIcon />
        </NavLink>
      </div>
      <div className={styles.socialItem}>
        <NavLink
          to="https://www.tiktok.com/@pm_kazakhstan/"
          target="_blank"
        >
          <TiktokIcon />
        </NavLink>
      </div>
      <div className={styles.socialItem}>
        <NavLink
          to="https://www.youtube.com/@ParimatchKazakhstan/featured"
          target="_blank"
        >
          <YoutubeIcon />
        </NavLink>
      </div>
    </div>
  );
};
