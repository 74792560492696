import styles from "./ActivityHistory.module.scss";
import check from "../../assets/images/icons/check-green.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import Pagination from "./Pagination/Pagination";
import info from "../../assets/images/info.svg";
import carret from "../../assets/images/carret.svg";
import { useTranslation } from "react-i18next";

const PER_PAGE = 6;

const ActivityHistory = () => {
  const { t } = useTranslation(["main", "errors", "activity_history"]);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page) => {
    const token = localStorage.getItem('auth_token')
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/my_pm_points_transactions`,
        {
          params: {
            page: page,
            per_page: PER_PAGE,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );
      if (response.data) {
        setProducts(response.data.data);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const errors = {
    bad_params: t("bad_params"),
    failed: t("failed"),
    bad_buyer: `${t("bad_buyer")}`,
    tradelink_outdated_error: `${t("tradelink_outdated_error")}`,
    tradelink_format_error: t("tradelink_format_error"),
    escrow_restriction_error: t("escrow_restriction_error"),
    private_inventory_error: `${t("private_inventory_error")}`,
    steam_trade_ban_error: `${t("steam_trade_ban_error")}`,
    tradelink_error: `${t("tradelink_error")}`,
    buyer_has_buy_items_ban: t("buyer_has_buy_items_ban"),
    cancelled: t("cancelled"),
    offer_expired: t("offer_expired"),
    offer_declined: t("offer_declined"),
    user_reached_limit_24h: `${t("user_reached_limit_24h")}`,
    default: t("default"),
  };

  const [visibleItem, setIsVisible] = useState(null);

  const handleShowInfo = (id) => {
    setIsVisible(id);
  };

  const handleCloseInfo = (id) => {
    setIsVisible(null);
  };

  const renderStatus = (product) => {
    let statusText;
    let statusStyle;

    if (product?.shop_item?.item_type === "merch_prize") {
      statusText = t('main:delivered')
    }
    else if (product.status === "pending" || product.status === 'sent') {
      statusText = t('main:send')
      statusStyle = styles.send
    }
    else if (product.status === 'completed') {
      statusText = t('main:delivered')
    } else {
      statusText = t('main:cancel')
      statusStyle = styles.failed
    }

    return (
      <div className={styles.status}>
        <div className={statusStyle}>
          {statusText}
          {product.status === "failed" && (
            <div className={styles.error}>
              <img
                onClick={() => handleShowInfo(product.id)}
                src={info}
                alt=""
              />

              <div
                className={styles.info}
                style={{
                  display: visibleItem === product.id ? "block" : "none",
                }}
              >
                <div className={styles.infoTitle}>{t("cancel_reason")}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: errors[product?.giveaway?.last_attempt_status]
                      ? errors[product?.giveaway?.last_attempt_status]
                      : errors[product?.shop_item?.item_type === "rea_bonus"]
                        ? errors.user_reached_limit_24h
                        : errors.default,
                  }}
                  className={styles.infoTxt}
                ></div>
                <div onClick={handleCloseInfo} className={styles.accept}>
                  {t("main:clear")}
                </div>
                <div className={styles.carret}>
                  <img src={carret} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.titleItem}>
          {t("activity_history:title")}
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr className={styles.head}>
            <th className={styles.headItem}>{t("activity_history:date")}</th>
            <th className={styles.headItem}>{t("activity_history:item")}</th>
            <th className={`${styles.headItem} ${styles.headerCenter}`}>
              {t("activity_history:amount")}
            </th>
            <th className={styles.headItem}>{t("activity_history:status")}</th>
          </tr>
        </thead>
        <tbody className={styles.body}>
          {products.map((product, index) => (
            <tr key={index} className={styles.bodyItem}>
              <td className={styles.bodyItemName}>
                {formatDate(product.created_at)}
              </td>
              <td className={styles.bodyItemName}>
                {product?.shop_item?.name
                  ? product?.shop_item?.name
                  : "PM баллы"}
              </td>
              <td className={styles.bodyItemName}>
                {Math.abs(product.amount)} points
              </td>
              <td>
                {renderStatus(product)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {pagination && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={pagination.total_pages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default ActivityHistory;
