import { useEffect } from "react";
import Promo from "../../components/Promo/Promo";
import styles from "./Home.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Cards from "../../components/Cards/Cards";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "@/shared/components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import PromoGroup from "../../components/PromoGroup/PromoGroup";
import { updateAuth } from "@store/slices/auth/authSlice";
import { RootState } from "@/store";
import { Banner, Prefooter } from "./components";
import { CybermarathonBanner } from "./components/CybermarathonBanner/CybermarathonBanner";
export const Home = () => {
  const { t } = useTranslation(["main", "notifications"]);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  useEffect(() => {
    const currentURL = window.location.href;

    const firstLogin = async () => {
      const token = localStorage.getItem('auth_token')
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/profile`,
          {
            first_login: false,
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              ...(token ? { Authorization: `Bearer ${token}` } : {})
            },
          }
        );

        if (response.data) {
          dispatch(
            updateAuth({
              isAuthenticated: true,
              user: response.data,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (currentURL.includes("?steam_auth=success")) {
      firstLogin();
      toast(<Toast message={t("notifications:steam_attached")} status="success" />, {
        hideProgressBar: true,
      });
    }
  }, [dispatch, t, isAuthenticated]);

  return (
    <div
      className={`${styles.container} ${
        isAuthenticated ? styles.auth : styles.purple
      }`}
    >

      {isAuthenticated ? (
        <Cards />
      ) : (
        <div>
          <Banner/>
          <Promo />
          <PromoGroup />
          <CybermarathonBanner />
          <Prefooter />
        </div>
      )}
    </div>
  );
};
