import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '@/shared/components'
import { updateAuth } from "@store/slices/auth/authSlice";
import { updateUserAuth } from "@utils/authUtils";

export const useBuyProduct = () => {
  const { t } = useTranslation(['main', 'notifications']);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const buyProduct = async (id, type) => {
    if (loading) return;

    const token = localStorage.getItem('auth_token')

    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shop_items/${id}/buy_${type}`,
        null,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );

      if (response.data) {
        if (type === "rea_bonus") {
          toast(
            <Toast
              message={t("notifications:present_send_bonus")}
              status="success"
            />,
            {
              hideProgressBar: true,
            }
          );
        } else if (type === "merch_prize") {
          toast(
            <Toast
              message={t("notifications:present_send_merch")}
              status="success"
            />,
            {
              hideProgressBar: true,
            }
          );
        } else {
          toast(
            <Toast
              message={t("notifications:present_send")}
              status="success"
            />,
            {
              hideProgressBar: true,
            }
          );
        }

        const user = await updateUserAuth();

        if (user) {
          dispatch(
            updateAuth({
              isAuthenticated: true,
              user: user,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);

      if (error.response) {
        if (
          error.response.data.errors.includes("user_has_not_enough_pm_points")
        ) {
          toast(
            <Toast
              message={t("notifications:not_enough_cash")}
              status="warning"
            />,
            {
              hideProgressBar: true,
            }
          );
        } else if (
          error.response.data.errors.includes("user_reached_limit_24h") ||
          error.response.data.errors.includes("user_reached_limit_per_user")
        ) {
          toast(
            <Toast
              message={"К сожалению ваш ежедневный лимит покупок превышен"}
              status="error"
            />,
            {
              hideProgressBar: true,
            }
          );
        }
      } else {
        toast(
          <Toast
            message={t("notifications:technical_issues")}
            status="error"
          />,
          {
            hideProgressBar: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    buyProduct,
    loading
  };
}; 