import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { LoginType } from '../../types';
import styles from './LoginInput.module.scss';
import { MobIcon } from '@/shared/icons';

interface LoginInputProps {
  type: LoginType;
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  isValid: boolean;
  isFocused: boolean;
  inputRef: any;
}

export const LoginInput = forwardRef<any, LoginInputProps>(({
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  isValid,
  isFocused,
  inputRef
}, ref) => {
  const { t } = useTranslation('main');

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  const getLabel = () => {
    switch (type) {
      case 'phone': return 'Телефон';
      case 'account': return isRockets ? 'ID' : t('parimatch_id');
      case 'email': return 'Email';
    }
  };

  const getPlaceholder = () => {
    switch (type) {
      case 'phone': return '+7 (XXX) XXX - XX - XX';
      case 'account': return isRockets ? 'ID' : t('parimatch_id');
      case 'email': return 'Email';
    }
  };

  

  const mask = isRockets ? '+{380}(00) 000 - 00 - 00' : '+{7}(000) 000 - 00 - 00'

  return (
    <div className={styles.row}>
      <div className={styles.label}>
        {getLabel()}<span>*</span>
      </div>
      <div className={`${styles.input} ${!isValid && styles.error} ${isFocused && styles.inputFocus}`}>
        <div className={styles.inputWrapper}>
          {type === 'phone' ? (
            <>
              <MobIcon />
              <IMaskInput
                mask={mask}
                lazy={false}
                unmask={true}
                ref={ref}
                inputRef={inputRef}
              onAccept={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
                placeholderChar="x"
              />
            </>
          ) : (
            <input
              type={type === 'email' ? 'email' : 'text'}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={getPlaceholder()}
            />
          )}
        </div>
      </div>
    </div>
  );
});

LoginInput.displayName = 'LoginInput'; 