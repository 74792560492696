import React, { createContext, useContext, useState } from 'react';
import { ModalName } from '@shared/components';

interface ModalContextType {
  activeModal: ModalName | null;
  openModal: (modalName: ModalName, data?: any) => void;
  closeModal: () => void;
  isModalOpen: (modalName: ModalName) => boolean;
  getModalData: <T>(modalName: ModalName) => T | null;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<ModalName | null>(null);
  const [modalData, setModalData] = useState<Record<string, any>>({});

  const openModal = (name: ModalName, data?: any) => {
    setActiveModal(name);
    if (data) {
      setModalData(prev => ({ ...prev, [name]: data }));
    }
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const getModalData = <T,>(name: ModalName): T | null => {
    return (modalData[name] as T) || null;
  };

  const isModalOpen = (modalName: ModalName) => {
    return activeModal === modalName;
  };

  return (
    <ModalContext.Provider value={{ activeModal, openModal, closeModal, isModalOpen, getModalData }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModals = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModals must be used within a ModalProvider');
  }
  return context;
};