import { Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './BonusModal.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const BonusModal = () => {
  const { isModalOpen, closeModal } = useModals();
  const { t } = useTranslation("bonus_reg_rules");

  return (
    <Modal isOpen={isModalOpen(ModalName.BONUS)} onClose={closeModal}>
      <div className={styles.title}>
        {t("header")}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.offer}>
          <p className={styles.justify}><strong>{t('justify')}</strong></p>
          <ul>
            <li>{t('li_1')}</li>
            <li>{t('li_2')}</li>
            <li>{t('li_3')}</li>
            <li>{t('li_4')}</li>
          </ul>
          <br />
          <p><b> {t('header_2')}</b></p>

          <ul>
            <li>{t('li_5')}</li>
          </ul>
        </div>
      </div>
    </Modal>
  )
}