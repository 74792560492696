import { Modal } from '@shared/components';
import { ModalName } from '../types';
import { useModals } from '@/shared/contexts/modal-context';
import styles from './OfferModal.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
export const OfferModal = () => {
  const { isModalOpen, closeModal } = useModals();
  const { t } = useTranslation("reg_offer_rules")

  return (
    <Modal isOpen={isModalOpen(ModalName.OFFER)} onClose={closeModal}>
      <div className={styles.title}>
        {t("header")}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.offer}>
          <p>
            <strong>{t("city")}</strong>
          </p>

          <br />
          <p>{t("p1")}</p>

          <br />
          <p>
            <strong>{t("warnning")}</strong>
          </p>

          <p>{t("warrning_text")}</p>

          <p style={{ marginLeft: "5px" }}>• {t("warrning_cause_1")}</p>
          <p style={{ marginLeft: "5px" }}>• {t("warrning_cause_2")}</p>
          <p style={{ marginLeft: "5px" }}>• {t("warrning_cause_3")}</p>
          <p style={{ marginLeft: "5px" }}>• {t("warrning_cause_4")}</p>

          <br />
          <p>{t("warrning_footer")}</p>
          <br />
          <p>
            <strong>{t("warrning_call")}</strong>
          </p>

          <br />
          <p>
            <strong>{t("responsible_game")}</strong>
          </p>

          <p>{t("responsible_game_1")}</p>

          <p>{t("responsible_game_2")}</p>
          <p style={{ marginLeft: "5px" }}> {t("responsible_cause_1")}</p>
          <p style={{ marginLeft: "5px" }}> {t("responsible_cause_2")}</p>
          <p style={{ marginLeft: "5px" }}> {t("responsible_cause_3")}</p>
          <p style={{ marginLeft: "5px" }}> {t("responsible_cause_4")}</p>

          <p style={{ marginLeft: "10px" }}>
            {" "}
            {t("responsible_cause_4_point_1")}
          </p>
          <p style={{ marginLeft: "10px" }}>
            {" "}
            {t("responsible_cause_4_point_2")}
          </p>
          <p style={{ marginLeft: "10px" }}>
            {" "}
            {t("responsible_cause_4_point_3")}
          </p>

          <br />
          <p>
            <strong>{t("self_limit")}</strong>
          </p>

          <p>{t("self_limit_1")}</p>

          <p>{t("self_limit_2")}</p>

          <p>{t("self_limit_3")}</p>

          <p>
            {t("self_limit_4")}
            <br />
            &nbsp;
            <br />
            <strong>{t("on_clinic_name")}</strong>
            <br />
            {t("on_clinic_email")} almaty@onclinic.kz
            <br />
            {t("adresse")}
            <br />
            {t("on_clinic_adresse_1")}
            <br />
            {t("on_clinic_adresse_2")}
            <br />
            {t("on_clinic_adresse_3")}
            <br />
            {t("on_clinic_adresse_4")}
            <br />
            {t("on_clinic_adresse_5")}
            <br />
            {t("site")}{" "}
            <NavLink to="https://onclinic.kz" target="_blank">
              https://onclinic.kz
            </NavLink>
          </p>

          <p>
            <br />
            <strong>{t("amanat_clinic_name")}</strong>
            <br />
            {t("on_clinic_email")} amanatrehab@gmail.com
            <br />
            {t("adresse")} {t("amanat_clinic_adresse")}
            <br />
            {t("site")}{" "}
            <NavLink to="https://amanatcenter.kz/" target="_blank">
              amanatcenter.kz
            </NavLink>
          </p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_1_header")}</strong>
          </p>

          <p>
            1.1. <strong>{t("state_1_1_name")}</strong> – {t("state_1_1")}
          </p>

          <p>
            1.2. <strong>{t("state_1_2_name")}</strong> – {t("state_1_2")}
          </p>

          <p>
            1.3. <strong>{t("state_1_3_name")}</strong> – {t("state_1_3")}
          </p>

          <p>
            1.4. <strong>{t("state_1_4_name")}</strong> – {t("state_1_4")}
          </p>

          <p>
            1.5. <strong>{t("state_1_5_name")}</strong> – {t("state_1_5")}
          </p>

          <p>
            1.6. <strong>{t("state_1_6_name")}</strong> – {t("state_1_6")}
          </p>

          <p>
            1.7. <strong>{t("state_1_7_name")}</strong> – {t("state_1_7")}
          </p>

          <p>
            1.8. <strong>{t("state_1_8_name")}</strong> – {t("state_1_8")}
          </p>

          <p>
            1.9. <strong>{t("state_1_9_name")}</strong> – {t("state_1_9")}
          </p>

          <p>
            1.10. <strong>{t("state_1_10_name")}</strong> – {t("state_1_10")}
          </p>

          <p>
            1.11. <strong>{t("state_1_11_name")}</strong> – {t("state_1_11")}
          </p>

          <p>
            1.12. <strong>{t("state_1_12_name")}</strong> – {t("state_1_12")}
          </p>
          <p>
            1.13. <strong>{t("state_1_13_name")}</strong> – {t("state_1_13")}
          </p>
          <p>
            1.14. <strong>{t("state_1_14_name")}</strong> – {t("state_1_14")}
          </p>
          <p>
            1.15. <strong>{t("state_1_15_name")}</strong> – {t("state_1_15")}
          </p>
          <p>
            1.16. <strong>{t("state_1_16_name")}</strong> – {t("state_1_16")}
          </p>
          <p>
            1.17. <strong>{t("state_1_17_name")}</strong> – {t("state_1_17")}
          </p>
          <p>
            1.18. <strong>{t("state_1_18_name")}</strong> – {t("state_1_18")}
          </p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_2_header")}</strong>
          </p>

          <p>{t("state_2_1")}</p>

          <p>{t("state_2_2")}</p>

          <p>{t("state_2_3")}</p>

          <p>{t("state_2_4")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_3_header")}</strong>
          </p>

          <p>
            <strong>{t("state_3_1")}</strong>
          </p>

          <p>{t("state_3_1_1")}</p>

          <p>{t("state_3_1_2")}</p>

          <p>{t("state_3_1_3")}</p>

          <p>{t("state_3_1_4")}</p>

          <p>{t("state_3_1_5")}</p>

          <p>{t("state_3_1_6")}</p>

          <ul>
            <li style={{ marginLeft: "5px" }}>• {t("state_3_1_6_1")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_3_1_6_2")}</li>
          </ul>

          <p>{t("state_3_1_7")}</p>

          <p>{t("state_3_1_8")}</p>

          <p>{t("state_3_1_9")}</p>

          <p>{t("state_3_1_10")}</p>

          <p>{t("state_3_1_11")}</p>

          <p>{t("state_3_1_12")}</p>

          <p>{t("state_3_1_13")}</p>

          <p>{t("state_3_1_14")}</p>

          <p>{t("state_3_1_15")}</p>

          <p>{t("state_3_1_16")}</p>

          <p style={{ marginLeft: "5px" }}>{t("state_3_1_16_a")}</p>

          <p style={{ marginLeft: "5px" }}>{t("state_3_1_16_b")}</p>

          <p style={{ marginLeft: "5px" }}>{t("state_3_1_16_c")}</p>

          <p>{t("state_3_1_17")}</p>

          {/* <p>Букмекер уведомляет Игрока о признании Ставки сомнительной.</p>

          <p>Если в течение 1095 дней после признания Букмекером Ставки или ее части сомнительной вышеуказанные расследования не будут завершены, то Букмекер по письменному запросу Игрока объявляет сомнительную Ставку недействительной (ничтожной) и производит возврат Ставки Игроку.</p>

          <p>Для целей настоящего пункта:</p> */}

          <p style={{ marginLeft: "5px" }}>{t("state_3_1_17_a")}</p>

          <p style={{ marginLeft: "5px" }}>{t("state_3_1_17_b")}</p>

          <p>{t("state_3_1_18")}</p>

          <br />
          <p>{t("state_3_2")}</p>

          <p>{t("state_3_2_1")}</p>

          <p>{t("state_3_2_2")}</p>

          <p>{t("state_3_2_3")}</p>

          <p>{t("state_3_2_4")}</p>

          <p>{t("state_3_2_5")}</p>

          <p>{t("state_3_2_6")}</p>

          <p>{t("state_3_2_7")}</p>

          <p>{t("state_3_2_8")}</p>

          <p>{t("state_3_2_9")}</p>

          <p>{t("state_3_2_10")}</p>

          <br />
          <p>
            <strong>{t("state_3_3")}</strong>
          </p>

          <p>{t("state_3_3_1")}</p>

          <p>{t("state_3_3_2")}</p>

          <p>{t("state_3_3_3")}</p>

          <p>{t("state_3_3_4")}</p>

          <p>{t("state_3_3_5")}</p>

          <br />
          <p>
            <strong>{t("state_3_4")}</strong>
          </p>

          <p>{t("state_3_4_1")}</p>

          <p>{t("state_3_4_2")}</p>

          <p>{t("state_3_4_3")}</p>

          <p>{t("state_3_4_4")}</p>

          <p>{t("state_3_4_5")}</p>

          <p>{t("state_3_4_6")}</p>

          <p>{t("state_3_4_7")}</p>

          <p>{t("state_3_4_8")}</p>

          <p>{t("state_3_4_9")}</p>

          <p>{t("state_3_4_10")}</p>

          <p>{t("state_3_4_11")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_4")}</strong>
          </p>

          <p>{t("state_4_1")}</p>

          <p>{t("state_4_2")}</p>

          <p>{t("state_4_3_head")}</p>
          <p style={{ marginLeft: "5px" }}>{t("state_4_3_1")}</p>
          <p style={{ marginLeft: "5px" }}>{t("state_4_3_2")}</p>
          <p style={{ marginLeft: "5px" }}>{t("state_4_3_3")}</p>
          <p>{t("state_4_3_bottom")}</p>

          <p>{t("state_4_4")}</p>

          <p>{t("state_4_5")}</p>

          <p>{t("state_4_6")}</p>

          <p>{t("state_4_7")}</p>

          <p>{t("state_4_8")}</p>

          <p>{t("state_4_9")}</p>

          <p>{t("state_4_10")}</p>

          <p>{t("state_4_11")}</p>

          <p>{t("state_4_12")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_5")}</strong>
          </p>

          <p>{t("state_5_1")}</p>

          <p>{t("state_5_2")}</p>

          <p>{t("state_5_3")}</p>

          <p>{t("state_5_4")}</p>

          <p>{t("state_5_5")}</p>

          <p>{t("state_5_6")}</p>

          <p>{t("state_5_7")}</p>

          <p>{t("state_5_8")}</p>

          <p>{t("state_5_9")}</p>

          <p>{t("state_5_10")}</p>

          <p>{t("state_5_11")}</p>

          <p>{t("state_5_12")}</p>

          <p>{t("state_5_13")}</p>

          <p>{t("state_5_14")}</p>

          <p>{t("state_5_15")}</p>

          <p>{t("state_5_16")}</p>

          <p>{t("state_5_17")}</p>

          <p>{t("state_5_18")}</p>

          <p>{t("state_5_19")}</p>

          <p>{t("state_5_20")}</p>

          <p>{t("state_5_21")}</p>

          <p>{t("state_5_22")}</p>

          <p>{t("state_5_23")}</p>

          <p>{t("state_5_24")}</p>

          <p>{t("state_5_25")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_6")}</strong>
          </p>

          <p>{t("state_6_1")}</p>

          <p>{t("state_6_2")}</p>

          <p>{t("state_6_3")}</p>

          <p>{t("state_6_4")}</p>

          <p>{t("state_6_5")}</p>

          <p>{t("state_6_6")}</p>

          <p>{t("state_6_7")}</p>

          <p>{t("state_6_8")}</p>

          <p>{t("state_6_9")}</p>

          <p>{t("state_6_10")}</p>

          <p>{t("state_6_11")}</p>

          <p>{t("state_6_12")}</p>

          <p>{t("state_6_13")}</p>

          <p>{t("state_6_14")}</p>

          <p>{t("state_6_15")}</p>
          <p>{t("state_6_16")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_7")}</strong>
          </p>

          <p>{t("state_7_1")}</p>

          <ul>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_a")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_b")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_c")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_d")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_e")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_f")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_g")}</li>
            <li style={{ marginLeft: "5px" }}>• {t("state_7_1_h")}</li>
          </ul>

          <br />
          <p>{t("state_7_2")}</p>

          <p>{t("state_7_3")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_8")}</strong>
          </p>

          <p>{t("state_8_1")}</p>

          <p>{t("state_8_2")}</p>

          <p>{t("state_8_3")}</p>

          <p>{t("state_8_4")}</p>

          <p>{t("state_8_5")}</p>

          <br />
          <p className={styles.center}>
            <strong>{t("state_9")}</strong>
          </p>

          <p>{t("state_9_1")}</p>

          <p>{t("state_9_2")}</p>

          <p>{t("state_9_3")}</p>

          <p>{t("state_9_4")}</p>

          <p>{t("state_9_5")}</p>

          <p>{t("state_9_6")}</p>

          <p>{t("state_9_7")}</p>

          <p>{t("state_9_8")}</p>

          <p>{t("state_9_9")}</p>
        </div>
      </div>
    </Modal>
  )
}