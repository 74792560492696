import styles from './Help.module.scss'
import { NavLink } from "react-router-dom"
import telegram from '../../assets/images/social/telegram.svg'
import phone from '../../assets/images/social/phone.svg'
import { useTranslation } from 'react-i18next'

export const Help = () => {
  const { t } = useTranslation(['main', 'faq'])

  return (
    <div>
      <div className={`${styles.container} container-main`}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>
              {t('help')}
            </div>
            <div className={styles.info}>
              Похоже, что у вас возникли проблемы со входом. <br />
              Попробуйте обратиться вслужбу поддержки.
            </div>
            <div className={styles.help}>
              <NavLink className={styles.tg}
                to={'https://t.me/PMKzSupport_bot'}
                target='_blank'>
                <img src={telegram} alt="" />

                @PMKzSupport_bot
              </NavLink>
              <NavLink className={styles.phone}
                to={'tel:9933'}>
                <img src={phone} alt="" />
                9933
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
